<template>
    <div>
        <v-row>
            <v-col md="5">
                <v-row class="mt-2">
                    <v-col md="6">
                        <v-card color="blue-grey-darken-1" variant="flat" class="text-center">
                            <template v-slot:subtitle>
                                LAST 3 MONTHS
                            </template>
                            <template v-slot:text>
                                <v-skeleton-loader v-if="entityRecordLoading" type="avatar"></v-skeleton-loader>
                                <h2 v-if="!entityRecordLoading">${{ numberWithCommas(entityRecord.salesLast3Months, 2) }}</h2>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col md="6">
                        <v-card color="cyan-darken-1" variant="flat" class="text-center">
                            <template v-slot:subtitle>
                                LAST 6 MONTHS
                            </template>
                            <template v-slot:text>
                                <v-skeleton-loader v-if="entityRecordLoading" type="avatar"></v-skeleton-loader>
                                <h2 v-if="!entityRecordLoading">${{ numberWithCommas(entityRecord.salesLast6Months, 2) }}</h2>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="6">
                        <v-card color="indigo-accent-2" variant="flat" class="text-center">
                            <template v-slot:subtitle>
                                LAST 12 MONTHS
                            </template>
                            <template v-slot:text>
                                <v-skeleton-loader v-if="entityRecordLoading" type="avatar"></v-skeleton-loader>
                                <h2 v-if="!entityRecordLoading">${{ numberWithCommas(entityRecord.salesLast12Months, 2) }}</h2>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col md="6">
                        <v-card color="amber-lighten-4" variant="flat" class="text-center">
                            <template v-slot:subtitle>
                                WIP
                            </template>
                            <template v-slot:text>
                                <v-skeleton-loader v-if="entityRecordLoading" type="avatar"></v-skeleton-loader>
                                <h2 v-if="!entityRecordLoading">${{ numberWithCommas(entityRecord.currentWIP, 2) }}</h2>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="7">
                <div style="height:250px; width:100%;" class="pa-3">
                    <Line :data="lineData" :options="lineOptions" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
</style>
<script>
    import { Line } from 'vue-chartjs'
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            lineOptions : {
                plugins: {
                    legend: {
                        display: true
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            },
                        }
                    }
                }
            }
        };
    }
    export default {
        name: "ContactDetailsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        components: {
            Line
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            numberWithCommas
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
            lineData() {
                if (this.entityRecord && this.entityRecord.monthlyPerformance) {
                    return {
                        labels: this.entityRecord.monthlyPerformance.map(item => { return this.$dayjs(item.day).format('MMM YY'); }),
                        datasets: [
                            {
                                label: 'Received',
                                fill: false,
                                tension: 0.1,
                                borderColor: '#00acc1',
                                data: this.entityRecord.monthlyPerformance.map(item => { return item.receivedTotal; })
                            },
                            {
                                label: 'Invoiced',
                                fill: true,
                                tension: 0.1,
                                borderColor: '#536dfe',
                                data: this.entityRecord.monthlyPerformance.map(item => { return item.invoiceTotal; })
                            },
                        ]
                    };
                }
                return { labels: [], datasets: [] };
            }
        }
    };
</script>