<template>
    <div class="buttons">
        <div class="button-controls d-flex justify-space-between">
            <v-btn prepend-icon="mdi-printer" @click="printPage">Print</v-btn>
        </div>
    </div>
    <div class="book">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <div v-if="!loading && data">
            <div v-for="job in data" :key="job.id">
                <job-card :job="job"></job-card>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import '../styles/portraitprint.scss';
    @media print {

    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { getTagClass } from '../mixins/jobMixins';
    import jobs from "../constants/jobs";
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: null,
            lines:[1,2,3,4,5,6]
        };
    }
    export default {
        name: "JobCardView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.date = this.$dayjs().format('YYYY-MM-DD');
            if (this.$route.query.ids){
                this.loadData(this.$route.query.ids);
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                'showErrorSnack'
            ]),
            numberWithCommas,
            getTagClass,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            printPage() {
                var css = '@page { size: A4 portrait!important; }',
                    head = document.head || document.getElementsByTagName('head')[0],
                    style = document.createElement('style');

                style.type = 'text/css';
                style.media = 'print';

                if (style.styleSheet){
                  style.styleSheet.cssText = css;
                } else {
                  style.appendChild(document.createTextNode(css));
                }

                head.appendChild(style);
                window.print();
            },
            loadData(ids) {
                this.loading = true;
                let route = `${this.$constants.entities.Job.ROUTE}jobcardlist?ids=${ids}`;
                this.$api.get(route)
                    .then(res => {
                        this.data = res.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error)
                        this.showErrorSnack('There was an issue getting the data');
                    });
            },
        },
        computed: {
        },
        watch: {
        }
    };
</script>