<template>
    <v-dialog v-model="showJobPretreatmentDialog"
            persistent
            min-width="80%"
            min-height="80%"
            width="auto">
        <v-card v-if="pretreatmentJob">
            <v-toolbar theme="dark">
                <v-toolbar-title>Confirm Pretreatment of {{ pretreatmentJob.name }}</v-toolbar-title>
                <v-btn icon="mdi-close"
                       @click="showJobPretreatmentDialog = false"></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-select label="Supervisor"
                          v-model="supervisor"
                          :items="supervisors"></v-select>
                <v-textarea label="Material Location" v-model="location" hint="Please give a description of where the material is after the pretreatment is complete."></v-textarea>
                <v-alert icon="mdi-alert-decagram-outline"
                         class="my-2"
                         v-if="completeErrors.length > 0"
                         type="warning">
                    <ul class="small-text">
                        <li :key="error" v-for="error in completeErrors">{{ error }}</li>
                    </ul>
                </v-alert>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="save"
                       :loading="pretreatSaving"
                       :disabled="completeErrors.length > 0"
                       prepend-icon="mdi-check-circle"
                       color="success">
                    SAVE PRETREATED
                </v-btn>
                <v-btn variant="tonal" @click="showJobPretreatmentDialog = false" :disabled="pretreatSaving">
                    CANCEL
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss'
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            location: '',
            supervisor: '',
            supervisors: ['John', 'Ina', 'Faiaia', 'Tafa', 'Jack', 'Tuvao', 'Cori']
        };
    }
    export default {
        name: "SmartPretreatmentDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "saveSmartPretreated"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            resetInput() {
                this.location = '';
                this.supervisor = '';
            },
            save() {
                this.saveSmartPretreated({ job: this.pretreatmentJob, date: this.$dayjs().format('YYYY-MM-DD') ,location: this.location, supervisor: this.supervisor });
            }
        },
        computed: {
            ...mapState({
                pretreatmentJob: (state) => state.smart.pretreatmentJob,
                pretreatSaving: (state) => state.smart.pretreatSaving
            }),
            showJobPretreatmentDialog: {
                get() {
                    return this.$store.state.smart.showJobPretreatmentDialog
                },
                set(value) {
                    this.$store.commit('setShowJobPretreatmentDialog', value)
                }
            },
            completeErrors() {
                let errors = [];
                if (!this.location || this.location.length == 0) {
                    errors.push('Location required');
                }
                if (!this.supervisor || this.supervisor.length == 0) {
                    errors.push('Supervisor required');
                }
                return errors;
            },
        },
        watch: {
            pretreatmentJob: {
                handler: function () {
                    if (this.pretreatmentJob) {
                        this.resetInput();
                    }
                }
            }
        }
    };
</script>