<template>
    <div>
        <v-card :loading="readyLoading"
                class="bg-light-blue-lighten-5 mt-2 text-black"
                variant="tonal">
            <v-card-title>
                <span class="font-weight-black"><v-icon icon="mdi-truck-check"></v-icon> Ready to Despatch</span>
            </v-card-title>
            <v-card-subtitle>
                Ready for pickup orders
            </v-card-subtitle>
            <v-card-text>
                <div>
                    <v-text-field label="Search..."
                                  prepend-icon="mdi-magnify"
                                  v-model="searchTerm"
                                  density="compact"
                                  clearable></v-text-field>
                </div>
                <v-table>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-left w-60">
                                    Order
                                </th>
                                <th class="text-left w-40">
                                    Customer
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in filteredReadyItems"
                                :key="item.name">
                                <td>
                                    <a href="javascript:void(0);" @click="processOutwards(item)"><span v-html="formatSearchName(item.name, searchTerm)"></span></a><br /><small v-if="item.customerNumber"><span v-html="formatSearchName(item.customerNumber, searchTerm)"></span></small>
                                </td>
                                <td>{{ item.contact.name }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            searchTerm: ''
        };
    }
    export default {
        name: "WarehouseReadyPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadReadyItems();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "loadReadyItems",
                "showAddTransaction"
            ]),
            formatSearchName,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            processOutwards(job) {
                this.showAddTransaction({ job: job, type: this.$constants.warehouse.TYP_OUTWARD });
            },
        },
        computed: {
            ...mapState({
                readyItems: (state) => state.warehouse.readyItems,
                readyLoading: (state) => state.warehouse.readyLoading,
            }),
            filteredReadyItems() {
                if (this.readyItems) {
                    if (this.searchTerm) {
                        return this.readyItems.filter(item => {
                            return item.name.includes(this.searchTerm) || item.customerNumber.includes(this.searchTerm);
                        });
                    } else {
                        return this.readyItems;
                    }
                }
                return [];
            }
        }
    };
</script>