<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field label="Transaction Date"
                      type="date"
                      :rules="[$rules.required]"
                      v-model="transactionDate"></v-text-field>
        <v-combobox v-model="colour"
                    :loading="coloursSelectLoading"
                    :items="coloursSelect"
                    item-title="selectName"></v-combobox>
        <v-text-field label="Quantity"
                      v-model="quantity"
                      :rules="[$rules.required]"
                      suffix="kgs"></v-text-field>
        <v-text-field v-model="reason"
                      label="Reason"
                      :rules="[$rules.required]"></v-text-field>
        <v-select v-model="rack"
                  label="Rack Location"
                  :items="racks"></v-select>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            name: "",
            valid: false,
            errors: null,
            transactionDate: null,
            rack: "",
            quantity: 1,
            reason: "",
            colour: null,
            racks: ['A1', 'B1', 'C1', 'B4']
        };
    }
    export default {
        name: "StockForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadSelectColours();
            this.transactionDate = this.$dayjs().format('YYYY-MM-DD');
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "loadSelectColours",
                "loadStock"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                formData.append('transactionDate', this.transactionDate);
                formData.append('rack', this.rack);
                formData.append('quantity', this.quantity);
                formData.append('reason', this.reason);
                formData.append('colourId', this.colour.id);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Created");
                        this.loadStock();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
                coloursSelect: (state) => state.colours.coloursSelect,
                coloursSelectLoading: (state) => state.colours.coloursSelectLoading
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            }
        }
    };
</script>