// Tags
const TAG_HIGH = "High Priority";
//const TAG_ONHOLD = "On Hold";
const TAG_REWORK = "Rework";
const TAG_BATCH = "Batch Oven";
// Status
const JOBSTAT_SCHEDULE = 1;
const JOBSTAT_ONHOLD = 2;
const JOBSTAT_UPCOMING = 3;
const JOBSTAT_BLASTING = 4;
const JOBSTAT_STRIPPING = 5;
const JOBSTAT_LINE1 = 6;
const JOBSTAT_LINE2 = 7;
const JOBSTAT_INVOICE = 8;
const JOBSTAT_COMP = 9;

export default {
    TAG_HIGH,
    //TAG_ONHOLD,
    TAG_REWORK,
    TAG_BATCH,
    tags: [
        {
            name: TAG_HIGH,
            icon: "mdi-flag",
            colour: "orange-darken-1"
        },
        {
            name: TAG_REWORK,
            icon: "mdi-recycle",
            colour: "red-darken-1"
        },
        {
            name: TAG_BATCH,
            icon: "mdi-toaster-oven",
            colour: "orange-darken-1"
        }
    ],
    JOBSTAT_SCHEDULE,
    JOBSTAT_ONHOLD,
    JOBSTAT_UPCOMING,
    JOBSTAT_BLASTING, 
    JOBSTAT_STRIPPING,
    JOBSTAT_LINE1,
    JOBSTAT_LINE2,
    JOBSTAT_INVOICE,
    JOBSTAT_COMP,
    statuses: [
        {
            id: JOBSTAT_SCHEDULE,
            icon: 'mdi-calendar-blank',
            title: 'Schedule',
            colour: 'light-blue'
        },
        {
            id: JOBSTAT_ONHOLD,
            icon: 'mdi-pause-octagon',
            title: 'On Hold',
            colour: 'orange'
        },
        {
            id: JOBSTAT_UPCOMING,
            icon: 'mdi-fast-forward',
            title: 'Upcoming',
            colour: 'blue'
        },
        {
            id: JOBSTAT_BLASTING,
            icon: 'mdi-spray',
            title: 'Blasting',
            colour: 'indigo'
        },
        {
            id: JOBSTAT_STRIPPING,
            icon: 'mdi-bucket-outline',
            title: 'Stripping',
            colour: 'brown'
        },
        {
            id: JOBSTAT_LINE1,
            icon: 'mdi-spray-bottle',
            title: 'Line 1',
            colour: 'blue-darken-3'
        },
        {
            id: JOBSTAT_LINE2,
            icon: 'mdi-spray-bottle',
            title: 'Line 2',
            colour: 'blue-darken-3'
        },
        {
            id: JOBSTAT_INVOICE,
            icon: 'mdi-invoice-text-outline',
            title: 'Invoice',
            colour: 'blue-accent-1',
        },
        {
            id: JOBSTAT_COMP,
            icon: 'mdi-check-circle',
            title: 'Completed',
            colour: 'green-lighten-2'
        },
    ]
}