<template>
    <div>
        <div class="d-flex">
            <div class="flex-1-0">
                <h1>General</h1>
                <p class="mb-2">Update general settings of the system.</p>
            </div>
        </div>
        <v-card flat>
            <v-card-text>
                <rates-display :type="$constants.rates.RATE_GENLENPER" :class-id="0" class-name="Product"></rates-display>
                <rates-display :type="$constants.rates.RATE_GENUNITSQM" :class-id="0" class-name="Product"></rates-display>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
</style>
<script>
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "SettingsGeneralView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
        }
    };
</script>