<template>
    <div>
        <div class="text-right">
            <v-btn color="primary"
                   @click="showAddContactProduct = true"
                   size="small"
                   prepend-icon="mdi-plus">
                ADD {{ reverseEntity.SINGULAR }}
            </v-btn>
        </div>
        <v-data-table-server v-model:items-per-page="itemsPerPage"
                             v-if="typeof(totalItems) != undefined"
                             :headers="tableHeaders"
                             :items-length="totalItems"
                             :items="tableItems"
                             :loading="tableLoading"
                             density="compact"
                             @update:itemsPerPage="itemsPerPageChange"
                             @update:page="pageChange"
                             fixed-header>
            <template v-slot:[`item.avatar`]="{ item }">
                <avatar-small :object="item"></avatar-small>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <span v-if="!reverseEntity.HASDETAIL">{{ item.name }}</span>
                <router-link v-if="reverseEntity.HASDETAIL" :to="'/'+reverseEntity.ROUTE+item.id">{{ item.name }}</router-link>
            </template>
            <template v-slot:[`item.controls`]="{ item }">
                <div class="text-right">
                    <v-btn-group divided
                                 variant="outlined"
                                 density="compact">
                        <v-btn prepend-icon="mdi-trash-can-outline"
                               @click="showConfirmDelete(item)"
                               :loading="item.deleting"
                               size="x-small">DELETE</v-btn>
                    </v-btn-group>
                </div>
            </template>
        </v-data-table-server>
        <v-dialog v-model="showAddContactProduct"
                  fullscreen
                  :scrim="false">
            <v-card>
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-plus"></v-icon> Adding a {{ reverseEntity.SINGULAR }} to this {{ entity.SINGULAR }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showAddContactProduct = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field :label="'Search for the '+reverseEntity.SINGULAR+'...'" v-model="searchTerm" :loading="searching" :disabled="saving"></v-text-field>
                    <v-list lines="one">
                        <v-list-item v-for="option in searchResults"
                                     :key="option.id"
                                     :value="option"
                                     :active="activeSearchResult(option)"
                                     @click="selectSearchResult(option)"
                                     color="primary">
                            <template v-slot:prepend>
                                <v-icon :icon="activeSearchResult(option) ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                            </template>
                            <template v-slot:title>
                                <span v-html="formatSearchName(option.name, searchTerm)"></span>
                            </template>
                            <div v-if="reverseEntity.TYPE == 'Contact'">
                                <div><i class="font-weight-bold">Email: </i><span v-html="formatSearchName(option.accountsEmail, searchTerm)"></span></div>
                                <div><i class="font-weight-bold">Phone: </i><span v-html="formatSearchName(option.phone, searchTerm)"></span></div>
                            </div>
                            <div v-if="reverseEntity.TYPE == 'Product'">
                                <div><i class="font-weight-bold">Type: </i><type-chip :type="option.type" entity="Product"></type-chip></div>
                                <div><i class="font-weight-bold">Code: </i><span v-html="formatSearchName(option.code, searchTerm)"></span></div>
                                <div><i class="font-weight-bold">UOM: </i>{{ option.uomType }}</div>
                            </div>
                        </v-list-item>
                    </v-list>
                    <div class="my-2">
                        <v-divider class="mb-2"></v-divider>
                        <v-btn :loading="saving"
                               @click="saveRecord"
                               flat
                               color="primary"
                               :disabled="!selectedRecord"
                               prepend-icon="mdi-floppy">SAVE</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmDelete"
                  width="auto">
            <v-card>
                <v-card-text v-if="deleteItem">
                    Are you sure you want to delete <strong v-if="deleteItem.entityString">{{ deleteItem.entityString }}</strong><span v-if="!deleteItem.entityString">this item</span>?
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="deleting"
                           color="primary"
                           prepend-icon="mdi-trash-can-outline"
                           @click="confirmDeleteItem"
                           dark>
                        DELETE
                    </v-btn>
                    <v-btn dark @click="confirmDelete = false" :disabled="deleting">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            showAddContactProduct: false,
            searchTerm: "",
            searching: false,
            selectedRecord: null,
            searchTimer: null,
            itemsPerPage: 10,
            totalItems: 0,
            tableItems: [],
            tableLoading: false,
            page: 1,
            searchResults: [],
            confirmDelete:false,
            deleteItem: null,
            deleting: false,
            sortBy: null
        };
    }
    export default {
        name: "ContactProductsTablePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadDatatable();
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            loadDatatable() {
                this.tableLoading = true;
                let route = this.entity.ROUTE + "contactproducts?id=" + this.object.id + "&page=" + this.page + "&itemsPerPage=" + this.itemsPerPage;
                if (this.sortBy && this.sortBy.length > 0) {
                    route += "&sortBy=" + this.sortBy[0].key + "&sortOrder=" + this.sortBy[0].order;
                }
                this.$api.get(route)
                    .then(res => {
                        this.tableLoading = false;
                        this.tableItems = res.data.items;
                        this.totalItems = res.data.totalItems;
                    })
                    .catch(error => {
                        this.tableLoading = false;
                        this.showErrorSnack('There was an error loading the table data.');
                    });
            },
            formatSearchName,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            activeSearchResult(option) {
                return this.selectedRecord != null && this.selectedRecord.id == option.id;
            },
            selectSearchResult(option) {
                this.selectedRecord = option;
            },
            searchReverse() {
                this.searching = true;
                let route = this.reverseEntity.ROUTE + "?searchTerm=" + this.searchTerm;
                if (this.reverseEntity.TYPE == "Product") {
                    route += "&type=3";
                }
                this.$api.get(route)
                    .then(res => {
                        this.searching = false;
                        this.searchResults = res.data.items;
                    }).catch(error => {
                        this.showErrorSnack('There was an error searching the table');
                        this.searching = false;
                    });
            },
            saveRecord() {
                this.saving = true;
                let formData = new FormData();
                if (this.entity.TYPE == "Product") {
                    formData.append('productId', this.object.id);
                    formData.append('contactId', this.selectedRecord.id);
                } else {
                    formData.append('productId', this.selectedRecord.id);
                    formData.append('contactId', this.object.id);
                }
                this.$api.post("contactproducts/", formData)
                    .then(res => {
                        this.saving = false;
                        this.showAddContactProduct = false;
                        this.loadDatatable();
                        this.reset();
                        this.showSuccessSnack("Created!");
                    }).catch(error => {
                        this.showErrorSnack('There was an error creating the record');
                        this.saving = false;
                    });
            },
            showConfirmDelete(item) {
                this.deleteItem = item;
                this.confirmDelete = true;
            },
            confirmDeleteItem(item) {
                this.deleting = true;
                let route = "contactproducts/";
                if (this.entity.TYPE == "Product") {
                    route += '?productId=' + this.object.id + '&contactId=' + this.deleteItem.id;
                } else {
                    route += '?productId=' + this.deleteItem.id + '&contactId=' + this.object.id;
                }
                this.$api.delete(route)
                    .then(res => {
                        item.deleting = false;
                        this.deleteItem = null;
                        this.confirmDelete = false;
                        this.loadDatatable();
                        this.showSuccessSnack("Removed!");
                    }).catch(error => {
                        item.deleting = false;
                        this.deleteItem = null;
                        this.confirmDelete = false;
                        this.showErrorSnack("Whoops. We ran into an error.");
                    });
            },
            itemsPerPageChange(itemsPerPage) {
                this.itemsPerPage = itemsPerPage;
                this.loadDatatable();
            },
            pageChange(page) {
                this.page = page;
                this.loadDatatable();
            }
        },
        computed: {
            ...mapState({
            }),
            title() {
                return this.entity.SINGULAR + " " + this.reverseEntity.PLURAL;
            },
            reverseEntity() {
                return this.entity.TYPE == "Product" ? this.$constants.entities.Contact : this.$constants.entities.Product;
            },
            tableHeaders() {
                if (this.entity.TYPE == "Contact") {
                    return [
                        { title: 'Code', key: 'code' },
                        { title: 'Name', key: 'name' },
                        { title: '', key: 'controls' },
                    ];
                } else {
                    return [
                        { title: '', key: 'avatar' },
                        { title: 'Name', key: 'name' },
                        { title: '', key: 'controls' },
                    ];
                }
            }
        },
        watch: {
            searchTerm: {
                handler: function () {
                    clearTimeout(this.searchTimer);
                    if (this.searchTerm.length > 2) {
                        this.searchTimer = setTimeout(this.searchReverse, 100);
                    } else {
                        this.searchResults = [];
                    }
                }
            },

        }
    };
</script>