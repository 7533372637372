<template>
    <entity-panel :id="id" :panel-entity="panelEntity"></entity-panel>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "ColourPanelView",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            id: String
        },
        methods: {
            ...mapActions([
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            panelEntity() {
                return this.$constants.entities.Colour;
            }
        }
    };
</script>