<template>
    <div>
        <v-overlay :model-value="staffLoading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex">
            <div class="flex-1-0">
                <h1><v-icon :icon="entity.ICON"></v-icon> {{ entity.PLURAL }}</h1>
            </div>
            <v-btn color="primary"
                   @click="showUploadModal"
                   prepend-icon="mdi-upload-circle-outline">
                Load from Payhero
            </v-btn>
        </div>
        <v-table density="comfortable">
            <thead>
                <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-right">
                        Worked 7 Days
                    </th>
                    <th class="text-right">
                        Leave 7 Days
                    </th>
                </tr>
            </thead>
            <tbody v-if="staff">
                <tr v-for="person in staff" :key="person.id">
                    <td class="text-left" @click="openStaff(person)"><a href="javascript:;">{{ person.name }}</a></td>
                    <td class="text-right">{{ numberWithCommas(totalStaffTime(person.workTimes, 7), 2) }}</td>
                    <td class="text-right">{{ numberWithCommas(totalStaffTime(person.leaveTimes, 7), 2) }}</td>
                </tr>
            </tbody>
            <tbody v-if="!staff">
                <tr>
                    <td colspan="3">No staff found</td>
                </tr>
            </tbody>
        </v-table>
        <v-dialog v-model="showPersonModal"
                  fullscreen
                  persistent
                  :scrim="false">
            <v-card v-if="showPerson">
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-account-circle"></v-icon> {{ showPerson.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showPersonModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row class="pt-3">
                        <v-col md="6">
                            <v-card color="indigo-accent-2" variant="flat" class="text-center">
                                <template v-slot:subtitle>
                                    LAST 7 DAYS
                                </template>
                                <template v-slot:text>
                                    <h5 class="mt-4">WORKED HOURS</h5>
                                    <h1 class="mt-2">{{ numberWithCommas(totalStaffTime(showPerson.workTimes, 7), 2) }}</h1>
                                    <h5 class="mt-4">LEAVE HOURS</h5>
                                    <h1 class="mt-2">{{ numberWithCommas(totalStaffTime(showPerson.leaveTimes, 7), 2) }}</h1>
                                </template>
                            </v-card>
                        </v-col>
                        <v-col md="6">
                            <v-card color="cyan-darken-1" variant="flat" class="text-center">
                                <template v-slot:subtitle>
                                    LAST 30 DAYS
                                </template>
                                <template v-slot:text>
                                    <h5 class="mt-4">WORKED HOURS</h5>
                                    <h1 class="mt-2">{{ numberWithCommas(totalStaffTime(showPerson.workTimes, 30), 2) }}</h1>
                                    <h5 class="mt-4">LEAVE HOURS</h5>
                                    <h1 class="mt-2">{{ numberWithCommas(totalStaffTime(showPerson.leaveTimes, 30), 2) }}</h1>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="pt-3">
                        <v-col md="6">
                            <h5>Recent Work Times</h5>
                            <v-table class="small-table text-caption" density="compact">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th class="text-right">Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="workedRow in showPerson.workTimes" :key="workedRow.id">
                                        <td>{{ workedRow.dateDescription }}</td>
                                        <td>{{ workedRow.typeDescription }}</td>
                                        <td class="text-right">{{ workedRow.durationHours }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                        <v-col md="6">
                            <h5>Recent Leave Times</h5>
                            <v-table class="small-table text-caption" density="compact">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th class="text-right">Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="workedRow in showPerson.leaveTimes" :key="workedRow.id">
                                        <td>{{ workedRow.dateDescription }}</td>
                                        <td>{{ workedRow.typeDescription }}</td>
                                        <td class="text-right">{{ workedRow.durationHours }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showModal"
                  persistent
                  fullscreen
                  :scrim="false">
            <v-card width="800">
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-upload-circle-outline"></v-icon> Load from Payhero</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <p>Drop one or both of following Payhero reports.</p>
                    <ul>
                        <li>Leave Report</li>
                        <li>Hours Worked Report</li>
                    </ul>
                    <div class="my-2">
                        <v-file-input label="Payhero reports" v-model="fileAttachments" multiple></v-file-input>
                    </div>
                    <v-checkbox v-model="addPay" label="Add weekly pay total?"></v-checkbox>
                    <div v-if="addPay" class="my-2">
                        <h4>Weekly Pay Total</h4>
                        <p>If you have completed a weekly pay run. Please enter the details below.</p>
                        <v-row>
                            <v-col>
                                <v-text-field label="Pay Start Date"
                                              type="date"
                                              v-model="startDate"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Pay End Date"
                                              type="date"
                                              v-model="endDate"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-text-field label="Pay Total"
                                      v-model.number="payTotal"
                                      type="number"
                                      prefix="$"></v-text-field>
                    </div>
                    <v-alert icon="mdi-alert-decagram-outline"
                             class="my-2"
                             v-if="saveErrors.length > 0"
                             type="warning">
                        <ul class="small-text">
                            <li :key="error" v-for="error in saveErrors">{{ error }}</li>
                        </ul>
                    </v-alert>
                    <div class="mt-2">
                        <v-btn :disabled="saveErrors.length > 0 || savingReport"
                               flat
                               :loading="savingReport"
                               color="primary"
                               @click="saveReport"
                               prepend-icon="mdi-floppy">SAVE</v-btn>
                        <v-btn flat class="ml-2" @click="showModal = false">CANCEL</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions } from "vuex";
    import { totalStaffTime } from "../mixins/staffMixins";
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            showModal: false,
            savingReport: false,
            fileAttachments: [],
            staff: [],
            staffLoading: false,
            showPerson: null,
            showPersonModal: false,
            startDate: null,
            endDate: null,
            addPay: false,
            payTotal: 0
        };
    }
    export default {
        name: "StaffView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            const today = this.$dayjs();
            const dayOfWeek = today.day();
            this.startDate = today.subtract(dayOfWeek + 6, 'day').format('YYYY-MM-DD');
            // Calculate last week's Sunday
            this.endDate = today.subtract(dayOfWeek, 'day').format('YYYY-MM-DD');
            console.log(this.startDate + " " + this.endDate);
            this.loadStaff();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            totalStaffTime,
            numberWithCommas,
            openStaff(person) {
                this.showPerson = person;
                this.showPersonModal = true;
            },
            showUploadModal() {
                this.showModal = true;
                this.fileAttachments = null;
            },
            loadStaff() {
                this.staffLoading = true;
                this.$api.get('/staff/overview')
                    .then(res => {
                        this.staff = res.data;
                        this.staffLoading = false;
                    }).catch(error => {
                        this.showErrorSnack("There was an issue getting the staff details");
                        this.staffLoading = false;
                    });
            },
            saveReport() {
                this.savingReport = true;
                let data = new FormData();
                if (this.fileAttachments && this.fileAttachments.length > 0) {
                    this.fileAttachments.forEach(file => {
                        data.append("Files[]", file);
                    });
                }
                if (this.addPay) {
                    data.append("StartDate", this.startDate);
                    data.append("EndDate", this.endDate);
                    data.append("TotalPay", this.payTotal);
                }
                this.$api.post("/Staff/uploadpayhero", data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.savingReport = false;
                    this.showModal = false;
                    this.showSuccessSnack(res.data);
                    this.loadStaff();
                }).catch(error => {
                    this.savingReport = false;
                    this.showErrorSnack("There was an issue upload the report");
                });
            },
        },
        computed: {
            entity() {
                return this.$constants.entities["Staff"];
            },
            saveErrors() {
                let errors = [];
                if ((!this.fileAttachments || this.fileAttachments.length <= 0) && (!this.addPay)) {
                    errors.push('Need upload file or adding pay');
                }
                if (this.addPay) {
                    if (!this.startDate || !this.endDate) {
                        errors.push('Need start and end date for adding pay cycle');
                    }
                    if (!this.payTotal || this.payTotal <= 0) {
                        errors.push('Need pay total and pay total must be greater then 0')
                    }
                }
                if (this.fileAttachments) {
                    this.fileAttachments.forEach(file => {
                        if (!file.name.toLowerCase().endsWith('.csv')) {
                            errors.push(file.name + ' - Upload file must be csv');
                        }
                    });
                }
                return errors;
            }
        }
    };
</script>