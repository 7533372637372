const ACCESS_STAN = 10;
const ACCESS_SUP = 12;
const ACCESS_MAN = 15;
const ACCESS_ADM = 100;
export default {
    ACCESS_STAN,
    ACCESS_SUP,
    ACCESS_MAN,
    ACCESS_ADM,
    accessLevels: [
        {
            level: ACCESS_STAN,
            icon: 'mdi-account',
            title: 'Standard',
        },
        {
            level: ACCESS_SUP,
            icon: 'mdi-badge-account',
            title: 'Support',
        },
        {
            level: ACCESS_MAN,
            icon: 'mdi-account-supervisor',
            title: 'Manager'
        },
        {
            level: ACCESS_ADM,
            icon: 'mdi-ninja',
            title: 'Admin'
        },
    ]
}