<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <contact-input v-model:contact="contact"></contact-input>
        <!--Error Display-->
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error"><span v-html="error[0]"></span></li>
            </ul>
        </v-alert>
        <!--Save Details-->
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { encodeContactBasic } from '../mixins/entityEncodeMixins';
    import { removeNull } from '../mixins/stringMixins';
    import _cloneDeep from "lodash/cloneDeep";
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            contact: {
                id: null,
                name: '',
                accountsEmail: '',
                phone: '',
                contactGroup: null,
                contactPeople: [
                    {
                        id: 0,
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        role: null,
                        decisionRole: null
                    }
                ]
            }
        };
    }
    export default {
        name: "ContactForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadContact();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "loadContactGroups",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            loadContact() {
                if (this.editEntity) {
                    this.contact = _cloneDeep(this.editEntity);
                }
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                formData = encodeContactBasic(formData, this.contact);
                if (this.contact.contactPeople) {
                    let i = 0;
                    this.contact.contactPeople.forEach(person => {
                        if (person.firstName || person.lastName || person.email) {
                            formData.append(`contactPeople[${i}].order`, i.toString());
                            formData.append(`contactPeople[${i}].firstName`, person.firstName);
                            formData.append(`contactPeople[${i}].lastName`, removeNull(person.lastName));
                            formData.append(`contactPeople[${i}].role`, removeNull(person.role));
                            formData.append(`contactPeople[${i}].email`, removeNull(person.email));
                            formData.append(`contactPeople[${i}].phone`, removeNull(person.phone));
                            formData.append(`contactPeople[${i}].decisionRole`, removeNull(person.decisionRole));
                            i++;
                        }
                    });
                }
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            }
        },
        watch: {
            editEntity: {
                handler() {
                    if (this.editEntity.id && !this.contact.id) {
                        this.loadContact();
                        this.$forceUpdate();
                    }
                },
                deep: true
            }
        }
    };
</script>