import api from '../../helpers/api';
import entities from '../../constants/entities';
export const warehouse = {
    state: {
        job: null,
        contact: null,
        loading: false,
        transactionPage: 1,
        transactionItemsPerPage: 10,
        transactions: [],
        transactionsTotalItems: 0,
        showAddTransaction: false,
        addTransactionJob: null,
        addTransactionContact: null,
        addTransactionType: null,
        readyItems: [],
        readyLoading: false,
    },
    mutations: {
        setWarehouseReadyItems(state, items) {
            state.readyItems = items;
        },
        setWarehouseReadyLoading(state, loading) {
            state.readyLoading = loading;
        },
        setWarehouseJob(state, job) {
            state.job = job;
        },
        setWarehouseContact(state, contact) {
            state.contact = contact;
        },
        setWarehouseTransactions(state, { transactions, totalItems }) {
            state.transactions = transactions;
            state.transactionsTotalItems = totalItems;
        },
        setWarehouseLoading(state, loading) {
            state.loading = loading;
        },
        setWarehouseTransactionPage(state, page) {
            state.transactionPage = page;
        },
        setWarehouseTransactionItemsPerPage(state, itemsPerPage) {
            state.transactionItemsPerPage = itemsPerPage;
        },
        setShowAddTransaction(state, show) {
            state.showAddTransaction = show;
        },
        setAddTransactionJob(state, job) {
            state.addTransactionJob = job;
        },
        setAddTransactionContact(state, contact) {
            state.addTransactionContact = contact;
        },
        setAddTransactionType(state, type) {
            state.addTransactionType = type;
        }
    },
    actions: {
        loadReadyItems({ commit, dispatch }) {
            commit('setWarehouseReadyLoading', true);
            api.get(entities.Job.ROUTE + 'entityjobs?page=1&itemsPerPage=200&uncollectedOnly=true')
                .then(res => {
                    commit('setWarehouseReadyLoading', false);
                    commit('setWarehouseReadyItems', res.data.items);
                }).catch(error => {
                    commit('setWarehouseReadyLoading', false);
                    dispatch("showErrorSnack", 'Cannot load transactions');
                });
        },
        initTransactions({ commit, dispatch }, { job, contact }) {
            commit('setWarehouseJob', job);
            commit('setWarehouseContact', contact);
            dispatch('loadTransactions');
        },
        loadTransactions({ commit, dispatch, state }) {
            commit("setWarehouseLoading", true);
            let route = entities.WarehouseTransaction.ROUTE;
            route += '?page=' + state.transactionPage;
            route += '&itemsPerPage=' + state.transactionItemsPerPage;
            if (state.job) {
                route += '&jobId=' + state.job.id;
            }
            if (state.contact) {
                route += '&contactId=' + state.contact.id;
            }
            api.get(route).then(res => {
                commit("setWarehouseLoading", false);
                commit("setWarehouseTransactions", { transactions: res.data.items, totalItems: res.data.totalItems });
            })
            .catch(error => {
                commit("setWarehouseLoading", false);
                dispatch("showErrorSnack", 'Cannot load transactions');
            });
        },
        showAddTransaction({ commit, dispatch, state }, { job, contact, type }) {
            commit('setShowAddTransaction', true);
            commit('setAddTransactionJob', job);
            commit('setAddTransactionContact', contact);
            commit('setAddTransactionType', type);
        }
    },
    getters: {
    }
}