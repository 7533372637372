<template>
    <div class="mt-2">
        <warehouse-panel :show-ready="true"></warehouse-panel>
    </div>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "WarehouseDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            data: Array
        },
        methods: {
            numberWithCommas
        },
        computed: {
        }
    };
</script>