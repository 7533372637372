<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-row class="pt-3">
            <v-col md="3" class="hidden-sm-and-down">
                <v-card variant="flat" class="mb-2" color="amber-lighten-4">
                    <template v-slot:subtitle>
                        <div class="text-center">UNSCHEDULED JOBS <v-chip size="x-small" v-if="unscheduledJobs">{{ unscheduledJobs.length }}</v-chip></div>
                    </template>
                    <div class="unscheduled-work">
                        <div class="pa-2">
                            <v-text-field label="Search..."
                                          prepend-icon="mdi-magnify"
                                          v-model="searchTerm"
                                          density="compact"
                                          clearable></v-text-field>
                        </div>
                        <v-card
                                v-for="unscheduledJob in filteredJobs"
                                :key="unscheduledJob.id"
                                density="compact"
                                rounded="0"
                                :subtitle="unscheduledJob.customerNumber"
                                class="unschedule-job"
                                @click="selectJob(unscheduledJob)"
                                variant="flat"
                                :class="getJobClasses(unscheduledJob)">
                            <v-card-title>
                                {{ unscheduledJob.name }} <entity-tag-chip :object="unscheduledJob" class="ml-1"></entity-tag-chip>
                            </v-card-title>
                            <v-card-text>
                                <contact-link :contact="unscheduledJob.contact"></contact-link>
                                <div v-if="unscheduledJob.colours">{{ unscheduledJob.colours }}</div>
                                <div v-if="unscheduledJob.jobSubTotal">${{ numberWithCommas(unscheduledJob.jobSubTotal, 2) }}</div>
                                <div v-if="unscheduledJob==schedulingJob">
                                    <div class="py-1">
                                        <v-btn :to="$constants.entities.Job.ROUTE+unscheduledJob.id"
                                               block
                                               variant="flat"
                                               color="primary">
                                            View Job
                                        </v-btn>
                                    </div>
                                    <job-items-small :items="unscheduledJob.jobItems"></job-items-small>
                                </div>
                            </v-card-text>
                            <v-divider></v-divider>
                        </v-card>
                    </div>
                </v-card>
            </v-col>
            <v-col>
                <v-card variant="flat" class="mb-3 pa-4 calendar-panel">
                    <job-schedule-panel v-model:job="schedulingJob"></job-schedule-panel>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { getTagClass } from "../../mixins/jobMixins";
    function initialState() {
        return {
            loading: false,
            schedulingJob: null,
            unscheduledJobs: [],
            searchTerm: ''
        };
    }
    export default {
        name: "ScheduleDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loading = true;
            this.$api.get('/dashboard/statusjobs?status=0')
                .then(res => {
                    this.unscheduledJobs = res.data;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.showErrorSnack("There was an issue getting the unscheduled jobs.");
                });
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            selectJob(job) {
                if (this.schedulingJob == job) {
                    this.schedulingJob = null;
                } else {
                    this.schedulingJob = job;
                }
            },
            getTagClass,
            numberWithCommas,
            getJobClasses(job){
                let result = { 'selected-job':job== this.schedulingJob };
                let tagClass = this.getTagClass(job.jobTag);
                if (tagClass){
                    result[tagClass] = true;
                }
                return result;
            }
        },
        computed: {
            filteredJobs() {
                if (this.unscheduledJobs) {
                    if (this.searchTerm && this.searchTerm.length > 0) {
                        let lowerSearch = this.searchTerm.toLowerCase();
                        return this.unscheduledJobs.filter(job =>
                            (job.customerNumber ?? '').toLowerCase().includes(lowerSearch) ||
                            (job.contact.name ?? '').toLowerCase().includes(lowerSearch) ||
                            (job.name ?? '').toLowerCase().includes(lowerSearch) ||
                            (job.colours ?? '').toLowerCase().includes(lowerSearch)
                        );
                    }
                    return this.unscheduledJobs;
                }
                return [];
            },
        },
        watch: {
            schedulingJob: {
                handler() {
                    if (this.schedulingJob && this.schedulingJob.status != 0) {
                        // Remove and close
                        let index = this.unscheduledJobs.findIndex(item => item.id == this.schedulingJob.id);
                        if (index > -1) {
                            this.unscheduledJobs.splice(index, 1);
                        }
                        this.schedulingJob = null;
                    }
                }
            }
        }
    };
</script>
<style scoped>
    .unscheduled-work {
        background-color: white;
        overflow-y:auto;
        overflow-x:hidden;
        height:600px;
    }
    .calendar-panel {
        min-height:650px;
    }
    .unschedule-job{ 
        cursor:pointer;
    }
    .selected-job {
        background-color: #BBDEFB!important;
    }
</style>