<template>
    <v-app>
        <router-view />
        <v-overlay :model-value="loggingIn"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="showSnack"
                    :color="snackType"
                    :timeout="2000"
                    multi-line>
            {{ snackMessage }}
            <template v-slot:actions>
            </template>
        </v-snackbar>
        <vue3-confirm-dialog />
    </v-app>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: 'App',
        computed: {
            ...mapState({
                snackMessage: (state) => state.app.snackMessage,
                snackType: (state) => state.app.snackType,
                loggingIn: (state) => state.auth.loggingIn
            }),
            showSnack: {
                get() {
                    return this.$store.state.app.showSnack;
                },
                set(value) {
                    this.$store.commit('setShowSnack', value);
                },
            },
        }
    }
</script>

<style>
</style>
