<template>
    <div>
        <v-dialog v-model="showAddTransaction"
                  fullscreen
                  persistent
                  :scrim="false">
            <v-card>
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-plus"></v-icon> Creating a Warehouse Transaction</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showAddTransaction = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <warehouse-transaction-from :job="addTransactionJob" :contact="addTransactionContact" :type="addTransactionType"></warehouse-transaction-from>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .thumbnail-image {
        padding: .25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: .25rem;
        max-width: 100%;
        height: auto;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions, mapState } from "vuex";
    import { api as viewerApi } from "v-viewer"
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "WarehouseAddDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                addTransactionType: (state) => state.warehouse.addTransactionType,
                addTransactionJob: (state) => state.warehouse.addTransactionJob,
                addTransactionContact: (state) => state.warehouse.addTransactionContact,
            }),
            showAddTransaction: {
                get() {
                    return this.$store.state.warehouse.showAddTransaction
                },
                set(value) {
                    this.$store.commit('setShowAddTransaction', value)
                }
            },
        },
        watch: {
        }
    };
</script>