<template>
    <div v-if="entity" class="mt-3">
        <div class="d-flex">
            <div class="flex-1-0">
                <h1><v-icon :icon="entity.ICON"></v-icon> {{ entity.PLURAL }}</h1>
            </div>
            <v-btn color="primary"
                   v-if="entity.FORM != null"
                   @click="addEntity"
                   prepend-icon="mdi-plus">
                ADD {{ entity.SINGULAR }}
            </v-btn>
        </div>
        <entity-data-table v-if="!entity.CUSTOMOVERVIEW"></entity-data-table>
        <v-card v-if="entity.CUSTOMOVERVIEW" rounded class="pa-1" flat>
            <v-card-text>
                <component :is="entity.CUSTOMOVERVIEW"></component>
            </v-card-text>
        </v-card>
        <edit-entity-dialog></edit-entity-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "EntityOverview",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadEntity(this.$constants.entities[this.entityType]);
        },
        props: {
            entityType: String
        },
        methods: {
            ...mapActions([
                'loadEntity',
                'addEntity'
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
            }),
        }
    };
</script>