<template>
    <div v-if="currentTask != null">
        <v-btn @click="showConfirm('starttimer')"
               :color="!currentTask.running ? 'success' : ''"
               variant="flat"
               class="ma-1"
               :disabled="currentTask.running || confirmChange || taskRunning">
            <v-icon icon="mdi-timer"></v-icon> <span v-if="!currentTask.running">Start</span><job-task-timer v-if="currentTask.running" :task="currentTask"></job-task-timer>
        </v-btn>
        <v-btn @click="showConfirm('pausetimer')"
               variant="flat"
               class="ma-1"
               :color="currentTask.running ? 'blue-grey-lighten-4' : ''"
               :disabled="confirmChange || (!currentTask.running) || taskRunning"><v-icon icon="mdi-pause-box-outline"></v-icon> Pause</v-btn>
        <v-btn @click="showConfirm('completetask')"
               variant="flat"
               class="ma-1"
               :color="currentTask.running ? 'success' : ''"
               :disabled="confirmChange || (!currentTask.running) || taskRunning"><v-icon icon="mdi-stop-circle"></v-icon> Finish</v-btn>
        <v-dialog v-model="confirmChange"
                  max-width="400"
                  persistent>
            <v-card :prepend-icon="confirmIcon"
                    :text="confirmText"
                    :title="confirmTitle">
                <template v-if="confirmMode == 'completetask' && job.status != $constants.jobs.JOBSTAT_LINE1 && job.status != $constants.jobs.JOBSTAT_LINE2" v-slot:text>
                    <div>
                        <small>Please indicate below the location where you have put the completed material in the warehouse if applicable.</small>
                        <v-textarea v-model="materialLocation"
                                    label="Material Location"
                                    single-line></v-textarea>
                    </div>
                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="confirmChange = false" :disabled="loadingConfirm" color="grey-darken-1" variant="flat" size="large">
                        Cancel
                    </v-btn>
                    <v-btn @click="acceptConfirm" :loading="loadingConfirm" color="success" variant="flat" size="large">
                        Confirm
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            loading: false,
            confirmChange: false,
            confirmMode: null,
            loadingConfirm: false,
            disableBtns: false,
            materialLocation:''
        };
    }
    export default {
        name: "JobTaskButtons",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object,
            taskRunning: Boolean,
            refresh: Number
        },
        methods: {
            numberWithCommas,
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "reloadHistory"
            ]),
            showConfirm(mode) {
                this.confirmMode = mode;
                this.confirmChange = true;
                this.materialLocation = '';
            },
            acceptConfirm() {
                this.loadingConfirm = true;
                let formData = new FormData();
                formData.append('id', this.job.id);
                formData.append('jobTaskId', this.job.jobTaskId);
                if (this.materialLocation && this.materialLocation.length > 0) {
                    formData.append('location', this.materialLocation);
                }
                this.$api.post('/jobs/' + this.confirmMode+"?id="+this.job.id, formData)
                    .then(res => {
                        this.$store.commit('updateDialogJob', res.data);
                        this.$emit('update:job', res.data);
                        this.loadingConfirm = false;
                        this.confirmChange = false;
                        this.$emit('update:refresh', this.refresh+1);
                    }).catch(error => {
                        this.showErrorSnack('We ran into an issue');
                    });
            },
        },
        computed: {
            ...mapState({
                smartStartDate: (state) => state.smart.smartStartDate,
                smartEndDate: (state) => state.smart.smartEndDate,
            }),
            taskName() {
                if (this.job && (this.job.status-1) in this.$constants.jobs.statuses) {
                    return this.$constants.jobs.statuses[this.job.status - 1].title;
                }
                return '';
            },
            currentTask() {
                if (this.job) {
                    return this.job.activeTask;
                }
                return null;
            },
            confirmIcon() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "mdi-timer";
                    case "completetask":
                        return "mdi-play-circle-outline";
                    case "pausetimer":
                        return "mdi-pause-box-outline";
                }
                return "";
            },
            confirmTitle() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "Confirm Start Timer";
                    case "completetask":
                        return "Confirm Complete Task";
                    case "pausetimer":
                        return "Confirm Pause Task";
                }
                return "";
            },
            confirmText() {
                switch (this.confirmMode) {
                    case "starttimer":
                        return "Are you sure you want to start the timer?";
                    case "completetask":
                        return "Are you sure you want to complete the task?";
                    case "pausetimer":
                        return "Are you sure you want to pause the task?";
                }
                return "";
            }
        }
    };
</script>