<template>
    <v-table v-if="jobItemTasks" denisity="compact" class="small-table text-caption">
        <thead>
            <tr>
                <th class="w-60">Product</th>
                <th class="w-30 text-right">Quantity</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="line in jobItemTasks" :key="line.id">
                <td>
                    {{ line.description }}
                </td>
                <td :class="{'full-select-quantity':line.quantity >= line.itemQuantity, 'part-select-quantity':line.quantity > 0}" class="no-select-quantity">
                    <v-text-field density="compact"
                                  reverse
                                  hide-details="auto"
                                  @change="$emit('update:jobItemTasks', jobItemTasks);"
                                  v-model.number="line.quantity"></v-text-field>
                    <v-row>
                        <v-col cols="12"><small>ORDER QTY: <b>{{ line.itemQuantity }}</b></small></v-col>
                    </v-row>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td><h4>TOTAL ITEMS</h4></td>
                <td class="text-right"><h4>{{ numberWithCommas(totalJobSelectItems,2) }}</h4></td>
            </tr>
        </tfoot>
    </v-table>
</template>
<style scoped>
    .no-select-quantity {
        background-color: #F5F5F5 !important;
    }

    .part-select-quantity {
        background-color: #E3F2FD !important;
    }

    .full-select-quantity {
        background-color: #BBDEFB !important;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobTaskItemSelectTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            jobItemTasks: Array
        },
        methods: {
            numberWithCommas
        },
        computed: {
            totalJobSelectItems() {
                let result = 0;
                if (this.jobItemTasks) {
                    this.jobItemTasks.forEach(item => {
                        result += item.quantity;
                    });
                }
                return result;
            }
        }
    };
</script>