<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-row :key="key">
            <v-col cols="10">
                <v-card title="Details" flat>
                    <v-card-subtitle>
                        Enter all the detail's you have for this job.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field label="Project Name"
                                      v-model="name"
                                      hint="You can provide a project name to this job if applicable"
                                      persistent-hint></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card title="Job Details">
                    <v-card-subtitle>
                        Review the details of the job below before saving.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list lines="one">
                            <v-list-item v-if="contact" :title="steps[0]" :subtitle="contact.name">
                                <div><i>Email: </i>{{ contact.accountsEmail }}</div>
                                <div><i>Phone: </i>{{ contact.phone }}</div>
                            </v-list-item>
                            <v-list-item v-if="name" title="Project Name" :subtitle="name"></v-list-item>
                        </v-list>
                        <div class="mt-2 pt-2">
                            <v-btn :loading="loading"
                                   type="submit"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-floppy">SAVE</v-btn>
                            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
    </v-form>
</template>
<style scoped>
    .small-table td, th {
        padding:0px!important;
        font-size:10px!important;
    }
    .charges-table td, th {
        padding: 0px 5px !important;
    }
    .charges-table .w-lg {
        width: 18%;
    }
    .charges-table .w-sm{
        width:8%;
    }
    .charges-table .w-xs {
        width:5%;
    }
    :deep(.v-field) {
        font-size: 0.875rem !important;
    }
    .total-line td {
        border-top: 2px solid black !important;
        border-bottom: 2px solid black;
    }
    .total-subline td {
        box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
    }
</style>
<script>
    import _cloneDeep from "lodash/cloneDeep";
    import { mapActions, mapState, mapGetters } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    import { numberWithCommas, roundAway2dp } from "../mixins/numberMixins";
    function initialState() {
        return {
            key: 0,
            saving: false,
            loading: false,
            name: "",
            code: "",
            valid: false,
            errors: null,
            step: 1,
            steps: ['Contact', 'Details', 'Charges', 'Tasks'],
            contactSearchTerm: "",
            contactSearching: false,
            addNewContactForm: false,
            contactSearchTimer: null,
            contactSearchResults: [],
            contact: null,
            lines: [],
            type: null,
            customerNumber: '',
            dateReceiveEstimate: null,
            pricingTimer: null
        };
    }
    export default {
        name: "JobForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.name = this.editEntity.projectName;
                this.contact = this.editEntity.contact;
            }
            this.dateReceiveEstimate = this.$dayjs().format('YYYY-MM-DD');
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities",
                "loadContactProducts",
                "loadSelectColours",
                "loadJobItemTemplates"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                formData.append('projectName', this.name);
                formData.append('contact.id', this.contact.id);
                formData.append('contact.contactGroup.id', this.contact.contactGroup.id);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Updated!");
                        this.reloadEntities();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
                contactProducts: (state) => state.products.contactProducts,
                contactProductsLoading: (state) => state.products.contactProductsLoading,
                coloursSelect: (state) => state.colours.coloursSelect,
                coloursSelectLoading: (state) => state.colours.coloursSelectLoading
            }),
            ...mapGetters([
                "filterJobItemTemplates",
                "templateJobItemTasks"
            ]),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            entityContact() {
                return this.$constants.entities.Contact;
            },
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.contactSearchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        this.contactSearchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },

        }
    };
</script>