<template>
    <div class="py-3">
        <v-sheet variant="outline" class="sheet-borders">
            <v-overlay contained v-model="ratesLoading" class="align-center justify-center">
                <v-progress-circular color="primary"
                                        indeterminate
                                        size="64"></v-progress-circular>
            </v-overlay>
            <v-card-actions v-if="!readOnly" class="no-padding">
                <b>{{ customHeader ? customHeader : details.title  }}</b>
                <v-btn :prepend-icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'" 
                        size="x-small" class="ml-2"
                        variant="outlined"
                        @click="show = !show">ADD NEW RATE</v-btn>
            </v-card-actions>
            <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>
                    <v-sheet>
                        <v-form v-model="valid" @submit.prevent="submitNewRate" :disabled="ratesLoading">
                            <small>Add New Rate</small>
                            <v-row>
                                <v-col>
                                    <v-text-field label="Effective Date"
                                                    type="date"
                                                    :rules="[$rules.required]"
                                                    v-model="newRateDate"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="newRateValue"
                                                    :rules="[$rules.required]"
                                                    persistent-hint
                                                    :prefix="details.prefix"
                                                    :suffix="details.suffix"
                                                    type="number"
                                                    :label="details.title"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn color="primary"
                                            type="submit"
                                            class="mt-2"
                                            prepend-icon="mdi-plus">
                                        ADD RATE
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-sheet>
                </div>
            </v-expand-transition>
            <v-table density="compact">
                <thead>
                    <tr>
                        <th class="text-left">
                            Date
                        </th>
                        <th class="text-left">
                            Value
                        </th>
                        <th v-if="!readOnly"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="rate in rates"
                        :key="rate.id">
                        <td>{{ $dayjs(rate.effectiveDate).format('DD/MM/YYYY') }}</td>
                        <td>{{ rate.value }}</td>
                        <td v-if="!readOnly">
                            <div class="text-right">
                                <v-btn-group divided
                                                variant="outlined"
                                                class="ma-1"
                                                density="compact">
                                    <v-btn prepend-icon="mdi-trash-can-outline"
                                            @click="submitDeleteRate(rate)"
                                            :disabled="rates.length == 1 && blockDelete"
                                            size="x-small">DELETE</v-btn>
                                </v-btn-group>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-sheet>
    </div>
</template>
<style scoped>
    .sheet-borders {
        border-top: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            newRateDate: null,
            newRateValue: null,
            show: false,
            valid: true,
            rates: [],
            ratesLoading: false
        };
    }
    export default {
        name: "RatesDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.newRateDate = this.$dayjs().format('YYYY-MM-DD');
            this.loadRates();
        },
        props: {
            type: Number,
            classId: Number,
            className: String,
            mandatory: Boolean,
            readOnly: Boolean,
            customHeader: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            loadRates() {
                this.ratesLoading = true;
                this.$api.get('rates/?type=' + this.type + "&className=" + this.className + "&classId=" + this.classId)
                    .then(res => {
                        this.ratesLoading = false;
                        this.rates = res.data.items;
                    })
                    .catch(error => {
                        this.ratesLoading = false;
                        this.showErrorSnack("There was an error loading the table data.");
                    });
            },
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submitNewRate() {
                this.ratesLoading = true;
                let formData = new FormData();
                formData.append('Type', this.type);
                formData.append('ClassId', this.classId);
                formData.append('ClassName', this.className);
                formData.append('EffectiveDate', this.newRateDate);
                formData.append('Value', this.newRateValue);
                this.$api.post('rates/', formData)
                    .then(res => {
                        this.newRateDate = null;
                        this.newRateValue = null;
                        this.showSuccessSnack('Rate added');
                        this.loadRates();
                    })
                    .catch(error => {
                        this.ratesLoading = false;
                        this.showErrorSnack('There was an error adding the rate');
                    });
            },
            submitDeleteRate(rate) {
                this.ratesLoading = true;
                this.$api.delete('rates/' + rate.id)
                    .then(res => {
                        this.showSuccessSnack('Rate removed');
                        this.loadRates();
                    })
                    .catch(error => {
                        this.ratesLoading = false;
                        this.showErrorSnack('There was an error adding the rate');
                    });
            },
        },
        computed: {
            details() {
                return this.$constants.rates.types[this.type];
            },
            blockDelete() {
                return this.mandatory;
            }
        }
    };
</script>