<template>
    <div>
        <v-data-table-server v-model:items-per-page="itemsPerPage"
                             v-if="typeof(totalItems) != undefined"
                             density="comfortable"
                             :headers="entity.TABLEHEADERS"
                             :items-length="totalItems"
                             :items="dataTableEntities"
                             :loading="entitiesLoading"
                             @update:itemsPerPage="itemsPerPageChange"
                             @update:page="pageChange"
                             fixed-header>
            <template v-slot:[`item.avatar`]="{ item }">
                <avatar-small :object="item"></avatar-small>
            </template>
            <template v-slot:[`item.transactionDate`]="{ item }">
                {{ $dayjs(item.transactionDate).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:[`item.dateCreated`]="{ item }">
                {{ $dayjs(item.dateCreated).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <type-chip :type="item.type" :entity="entity.TYPE"></type-chip>
            </template>
            <template v-slot:[`item.rgb`]="{ item }">
                <rgb-square v-bind="item"></rgb-square>
            </template>
            <template v-slot:[`item.complexity`]="{ item }">
                <complexity-display :complexity="item.complexity"></complexity-display>
            </template>
            <template v-slot:[`item.jobTotal`]="{ item }">
                ${{ numberWithCommas(item.jobTotal, 2) }}
            </template>
            <template v-slot:[`item.calculation`]="{ item }">
                <equation-display :equation="item.calculation"></equation-display>
            </template>
            <template v-slot:[`item.contactName`]="{ item }">
                <span v-if="!item.contactId">{{ item.contactName }}</span>
                <router-link v-if="item.contactId" :to="$constants.entities.Contact.ROUTE+item.contactId">{{ item.contactName }}</router-link>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <span v-if="!entity.HASDETAIL">{{ item.name }}</span>
                <router-link v-if="entity.HASDETAIL" :to="entity.ROUTE+item.id">{{ item.name }}</router-link>
            </template>
            <template v-slot:[`item.controls`]="{ item }">
                <div class="text-right">
                    <v-btn-group divided
                                 variant="outlined"
                                 density="compact">
                        <v-btn prepend-icon="mdi-pencil-outline"
                               v-if="hasEdit"
                               @click="editEntity(item)"
                               size="x-small">EDIT</v-btn>
                        <v-btn prepend-icon="mdi-trash-can-outline"
                               v-if="hasDelete"
                               @click="showConfirmDelete(item)"
                               size="x-small">DELETE</v-btn>
                    </v-btn-group>
                </div>
            </template>
        </v-data-table-server>
        <v-dialog v-model="confirmDelete"
                  width="auto">
            <v-card>
                <v-card-text v-if="deleteItem">
                    Are you sure you want to delete <strong v-if="deleteItem.entityString">{{ deleteItem.entityString }}</strong><span v-if="!deleteItem.entityString">this item</span>?
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="deleting"
                           color="primary"
                           prepend-icon="mdi-trash-can-outline"
                           @click="confirmDeleteItem"
                           dark>
                        DELETE
                    </v-btn>
                    <v-btn dark @click="confirmDelete = false" :disabled="deleting">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            confirmDelete: false,
            deleteItem: null,
            currentlyDeleting: false,
            hasEdit: false,
            hasDelete: false,
            deleting: false
        };
    }
    export default {
        name: "EntityDataTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.hasEdit = this.entity && this.entity.UPDATE;
            this.hasDelete = this.entity && this.entity.DELETE;
        },
        props: {
        },
        methods: {
            numberWithCommas,
            ...mapActions([
                'loadDataTable',
                'updateDataTable',
                'editEntity',
                'showSuccessSnack',
                'showErrorSnack'
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            showConfirmDelete(item) {
                this.deleteItem = item;
                this.confirmDelete = true;
            },
            confirmDeleteItem() {
                this.deleting = true;
                this.$api.delete(this.entity.DELETE + this.deleteItem.id)
                    .then(res => {
                        this.confirmDelete = false;
                        this.deleteItem = null;
                        this.deleting = false;
                        this.loadDataTable();
                        this.showSuccessSnack("Removed!");
                    }).catch(error => {
                        this.deleting = false;
                        this.showErrorSnack("Whoops. We ran into an error.");
                    });
            },
            pageChange(page){
                this.page = page;
                this.loadDataTable();
            },
            itemsPerPageChange(perPage){
                this.itemsPerPage = perPage;
                this.loadDataTable();
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                totalItems: (state) => state.entities.totalItems,
                entitiesLoading: (state) => state.entities.entitiesLoading,
            }),
            ...mapGetters([
                "dataTableEntities"
            ]),
            itemsPerPage: {
                get() {
                    return this.$store.state.entities.itemsPerPage
                },
                set(value) {
                    this.$store.commit('setEntitiesItemsPerPage', value)
                }
            },
            page: {
                get() {
                    return this.$store.state.entities.page
                },
                set(value) {
                    this.$store.commit('setEntitiesPage', value)
                }
            },
            form() {
                return this.entity.FORM;
            }
        },
        watch: {
        }
    };
</script>