<template>
    <div>
        <v-btn color="primary" 
               block 
               prepend-icon="mdi-briefcase-plus"
               variant="flat"
               @click="showDialog = true" class="add-to-job-btn">ADD TO JOB</v-btn>
        <v-dialog v-model="showDialog"
                fullscreen>
            <v-card v-if="job">
                <v-toolbar theme="dark">
                    <v-toolbar-title>Add to {{ job.name }}</v-toolbar-title>
                    <v-btn icon="mdi-close"
                           @click="showDialog = false"></v-btn>
                </v-toolbar>
                <v-card-text>
                    <p>What would you like to add?</p>
                    <v-radio-group v-model="addType">
                        <v-radio label="File" value="file"></v-radio>
                        <v-radio label="Warehouse Transaction" value="warehouse"></v-radio>
                    </v-radio-group>
                    <div v-if="addType == 'file'" class="my-1">
                        <files-panel :object="job" :entity="$constants.entities.Job"></files-panel>
                    </div>
                    <div v-if="addType == 'warehouse'" class="my-1">
                        <warehouse-transaction-from v-model:show="showDialog" :job="job" :contact="job.contact"></warehouse-transaction-from>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn variant="tonal" @click="showDialog = false" :disabled="saving">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped lang="scss">
    .add-to-job-btn {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            addType: null,
            showDialog: false,
        };
    }
    export default {
        name: "AddToJob",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
            }),
        },
        watch: {
        }
    };
</script>