<template>
    <div class="search-bar">
        <v-dialog v-model="showSearch"
                  scrollable
                  width="auto">
            <template v-slot:activator>
                <v-btn prepend-icon="mdi-magnify" variant="outlined" @click="openDialog">Search</v-btn>
            </template>
            <v-card theme="dark" class="searchCard">
                <div class="search-input pa-2">
                    <v-text-field clearable
                                  :loading="loading"
                                  ref="searchInput"
                                  v-model="searchTerm"
                                  label="Looking for..."
                                  prepend-icon="mdi-magnify"></v-text-field>
                    <v-checkbox label="Show deleted?" v-model="showDeleted" density="compact" hide-details></v-checkbox>
                </div>
                <div class="search-results pa-2">
                    <v-list density="compact" :loading="loading">
                        <v-list-item v-if="searchTerm && items && searchTerm.length > 0 && items.length == 0 && !loading">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-alert-circle-outline"></v-icon>
                            </template>
                            <v-list-item-title>No results found</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="(item, i) in items"
                                     :key="i"
                                     :to="itemLink(item)"
                                     @click="clearSearch"
                                     :value="item"
                                     color="primary">
                            <template v-slot:prepend>
                                <v-icon :icon="itemIcon(item.entityType)"></v-icon>
                            </template>
                            <v-list-item-title>{{ item.name }} <v-chip color="red-darken-3" class="ml-1" variant="flat" size="x-small" v-if="item.dateDeleted">Deleted</v-chip></v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="item.addReference">{{ item.addReference }}</span>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .searchCard {
        width:500px;
    }
    .search-input {
        background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
        background-blend-mode: multiply;
    }
    .search-results {
        height:500px;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
            showSearch: false,
            items: [],
            searchTerm: '',
            timer: null,
            showDeleted: false
        };
    }
    export default {
        name: "SearchBar",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            doSearch() {
                this.loading = true;
                this.$api('/search?keyword=' + this.searchTerm + '&showDeleted=' + this.showDeleted)
                    .then(res => {
                        this.loading = false;
                        this.items = res.data;
                    });
            },
            itemIcon(entityType) {
                return this.$constants.entities[entityType].ICON;
            },
            itemLink(item) {
                return "/" + this.$constants.entities[item.entityType].ROUTE + item.id;
            },
            clearSearch() {
                this.searchTerm = '';
                this.showSearch = false;
            },
            setFocusName() {
                this.$refs.searchInput.focus();
            },
            openDialog() {
                this.showSearch = !this.showSearch
                setTimeout(() => {
                    this.setFocusName()
                }, 200)
            }
        },
        computed: {
        },
        watch: {
            searchTerm: {
                handler: function () {
                    if (this.searchTerm && this.searchTerm.length >= 4) {
                        clearTimeout(this.timer);
                        this.timer = setTimeout(this.doSearch, 400);
                    } else {
                        this.items = [];
                    }

                }
            },
            showDeleted: {
                handler: function () {
                    if (this.searchTerm && this.searchTerm.length >= 4) {
                        this.doSearch();
                    } else {
                        this.items = [];
                    }
                }
            }
        }
    };
</script>