// Groups
const TYP_INWARD = 0;
const TYP_OUTWARD = 1;

export default {
    TYP_INWARD,
    TYP_OUTWARD,
    types: [
        {
            title: "Inwards",
            value: TYP_INWARD,
            colour: '#536DFE',
            icon: 'mdi-package-variant-closed-plus'
        },
        {
            title: "Outwards",
            value: TYP_OUTWARD,
            colour: '#546E7A',
            icon: 'mdi-truck-delivery-outline'
        },
    ]
}