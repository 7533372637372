<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-row :key="key">
            <v-col cols="10">
                <v-stepper v-model="step" :items="steps">
                    <template v-slot:[`item.1`]>
                        <v-card :title="entityContact.SINGULAR" flat>
                            <v-card-subtitle>
                                Find the {{ entityContact.SINGULAR }} who this job is for. If they are new, then add.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-text-field :label="'Search for the '+entityContact.SINGULAR+'...'" v-model="contactSearchTerm" :loading="contactSearching"></v-text-field>
                                <v-list lines="one">
                                    <v-list-item v-for="option in contactSearchResults"
                                                 :key="option.id"
                                                 :value="option"
                                                 :active="activeContact(option)"
                                                 @click="selectContact(option)"
                                                 color="primary">
                                        <template v-slot:prepend>
                                            <v-icon :icon="activeContact(option) ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                                        </template>
                                        <template v-slot:title>
                                            <span v-html="formatSearchName(option.name, contactSearchTerm)"></span>
                                        </template>
                                        <template v-slot:subtitle>
                                            <div><i>Email: </i><span v-html="formatSearchName(option.accountsEmail, contactSearchTerm)"></span></div>
                                            <div><i>Phone: </i><span v-html="formatSearchName(option.phone, contactSearchTerm)"></span></div>
                                        </template>
                                    </v-list-item>
                                </v-list>
                                <div v-if="contactSearchTerm.length > 0 && !contactSearching" class="align-center justify-center">
                                    <p class="mb-3">Can't find the {{ entityContact.SINGULAR }}?</p>
                                    <v-btn color="primary"
                                           @click="showAddContact"
                                           prepend-icon="mdi-plus">
                                        ADD {{ entityContact.SINGULAR }}
                                    </v-btn>
                                </div>
                                <div v-if="contact">
                                    <hr class="my-2" />
                                    <h3 v-if="!contact.id">New {{ entityContact.SINGULAR }}</h3>
                                    <h3 v-if="contact.id">Editing {{ entityContact.SINGULAR }}</h3>
                                    <contact-input v-model:contact="contact"></contact-input>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template> 
                    <template v-slot:[`item.2`]>
                        <v-card title="Details" flat>
                            <v-card-subtitle>
                                Enter all the detail's you have for this job.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-text-field label="Job Name"
                                              v-model="name"
                                              hint="You can provide a name to this job if applicable"
                                              persistent-hint></v-text-field>
                                <v-text-field label="Customer Number"
                                              v-model="customerNumber"
                                              hint="Enter the customers PO number or any other customer identified number"
                                              persistent-hint></v-text-field>
                                <v-text-field label="Estimated Receive Date"
                                              type="date"
                                              v-model="dateReceiveEstimate"></v-text-field>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:[`item.3`]>
                        <v-card title="Charges" flat>
                            <v-card-subtitle>
                                Enter the charges for this job. Try and use existing product definitions if it is an unknown.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-table class="charges-table" fixed-header>
                                    <thead>
                                        <tr>
                                            <th class="text-left w-lg">
                                                Product
                                            </th>
                                            <th class="text-left w-lg">
                                                Charge
                                            </th>
                                            <th class="text-left w-lg">
                                                Colour
                                            </th>
                                            <th class="text-left w-lg">
                                                UOM
                                            </th>
                                            <th class="text-left w-sm text-right">
                                                Quantity
                                            </th>
                                            <th class="text-left w-sm text-right">
                                                Price
                                            </th>
                                            <th class="text-left w-sm text-right">
                                                Total
                                            </th>
                                            <th class="w-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(line, index) in lines" :key="line.order">
                                        <tr v-for="(charge, chargeIndex) in line.jobItemCharges" :key="charge.id">
                                            <td>
                                                <v-combobox v-model="line.product"
                                                            :rules="[$rules.required]"
                                                            variant="plain"
                                                            @update:modelValue="productChange(line)"
                                                            :loading="contactProductsLoading"
                                                            :items="contactProducts"
                                                            item-title="name"
                                                            v-if="chargeIndex == 0"></v-combobox>
                                            </td>
                                            <td>
                                                <v-select v-model="charge.jobItemTemplate"
                                                            :rules="[$rules.required]"
                                                            :disabled="!line.product"
                                                            return-object
                                                            variant="plain"
                                                            @update:modelValue="jobItemChange(line, charge)"
                                                            :items="productsPricingItems(line)"
                                                            item-title="name"></v-select>
                                            </td>
                                            <td>
                                                <v-combobox v-model="line.colour"
                                                            :disabled="!lineNeedsColour(line)"
                                                            :loading="coloursSelectLoading"
                                                            variant="plain"
                                                            :items="coloursSelect"
                                                            @update:modelValue="loadPricing(line, charge)"
                                                            item-title="selectName"
                                                            v-if="chargeIndex == 0"></v-combobox>
                                            </td>
                                            <td>
                                                <div v-if="chargeIndex == 0">
                                                    <div v-if="lineUom(line) == 'Periphery Length' || lineUom(line) == 'Length Only'">
                                                        <v-text-field label="Length"
                                                                      v-model.number="line.length"
                                                                      variant="plain"
                                                                      class="small-field"
                                                                      :disabled="lineFixedSize(line)"
                                                                      @update:modelValue="loadPricing(line, charge)"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                    </div>
                                                    <div v-if="lineUom(line) == 'Area Periphery'" class="d-flex">
                                                        <v-text-field label="Width"
                                                                      v-model.number="line.width"
                                                                      variant="plain"
                                                                      class="small-field"
                                                                      :disabled="lineFixedSize(line)"
                                                                      @update:modelValue="loadPricing(line, charge)"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                        <v-text-field label="Height"
                                                                      v-model.number="line.height"
                                                                      variant="plain"
                                                                      :disabled="lineFixedSize(line)"
                                                                      @update:modelValue="loadPricing(line, charge)"
                                                                      class="ml-2"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                        <v-text-field label="Depth"
                                                                      v-model.number="line.depth"
                                                                      variant="plain"
                                                                      :disabled="lineFixedSize(line)"
                                                                      @update:modelValue="loadPricing(line, charge)"
                                                                      class="ml-2"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              reverse
                                                              v-if="chargeIndex == 0"
                                                              v-model.number="line.quantity"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain" 
                                                              suffix="$"
                                                              disabled 
                                                              reverse
                                                              :model-value="lineUnit(charge)"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              disabled
                                                              suffix="$"
                                                              reverse
                                                              :model-value="numberWithCommas(lineTotal(line, charge), 2)"></v-text-field>
                                            </td>
                                            <td>
                                                <v-btn-group divided
                                                             variant="outlined">
                                                    <v-tooltip location="top" text="Add Charge">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn icon="mdi-format-list-group-plus"
                                                                   @click="addCharge(line)"
                                                                   v-if="chargeIndex == 0"
                                                                   v-bind="props"
                                                                   size="x-small">
                                                            </v-btn>
                                                        </template>
                                                    </v-tooltip>
                                                    <v-tooltip location="top" text="Copy Line">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn icon="mdi-content-copy"
                                                                   @click="copyLine(line)"
                                                                   v-if="chargeIndex == 0"
                                                                   v-bind="props"
                                                                   size="x-small">
                                                            </v-btn>
                                                        </template>
                                                    </v-tooltip>
                                                    <v-tooltip location="top" :text="index == 0 ? 'Delete Line' : 'Delete Charge'">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn icon="mdi-trash-can-outline"
                                                                   @click="deleteLine(index, chargeIndex)"
                                                                   :disabled="index == 0 && chargeIndex == 0"
                                                                   v-bind="props"
                                                                   size="x-small">
                                                            </v-btn>
                                                        </template>
                                                    </v-tooltip>
                                                </v-btn-group>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="8">
                                                <v-btn block prepend-icon="mdi-plus" @click="addProductLine">ADD LINE</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="total-subline">
                                            <td colspan="4" class="text-right"><h4 class="pr-2">TOTAL ITEMS</h4></td>
                                            <td class="text-right">
                                                <h4>{{ quantityTotal }}</h4>
                                            </td>
                                            <td colspan="3"></td>
                                        </tr>
                                        <tr class="total-subline">
                                            <td colspan="6" class="text-right"><h4 class="pr-2">SUBTOTAL</h4></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(chargeSubTotals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="total-subline">
                                            <td colspan="6" class="text-right"><h4 class="pr-2">TOTAL GST 15%</h4></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(chargeTaxTotals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="total-line">
                                            <td colspan="6" class="text-right"><h3 class="pr-2">TOTAL</h3></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(chargeTotals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </v-table>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:[`item.4`]>
                        <v-card title="Tasks" flat>
                            <v-card-subtitle>
                                Below is the identified list of tasks from your selected charges. Please select any optional tasks you know is required.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-list lines="one" select-strategy="classic" density="compact">
                                    <v-list-item v-for="item in lineTemplateTasks"
                                                 :key="item.type"
                                                 :active="item.selected"
                                                 :color="item.mandatory ? 'secondary' : 'primary'"
                                                 @click="toggleItemTask(item)">
                                        <template v-slot:prepend>
                                            <v-list-item-action start>
                                                <v-switch v-model="item.selected" :disabled="item.mandatory"></v-switch>
                                            </v-list-item-action>
                                        </template>
                                        <v-list-item-title><v-icon :icon="taskDetail(item.type).icon" class="mr-2"></v-icon> {{ taskDetail(item.type).title }}</v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ item.mandatory ? 'Mandatory task' : 'Optional task'}}
                                        </v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:actions>
                        <v-stepper-actions @click:next="moveNext" @click:prev="movePrevious" 
                                           :disabled="stepperActionsDisabled"></v-stepper-actions>
                    </template>
                </v-stepper>
            </v-col>
            <v-col cols="2">
                <v-card title="Job Details">
                    <v-card-subtitle>
                        Review the details of the job below before saving.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list lines="one">
                            <v-list-item v-if="contact" :title="steps[0]" :subtitle="contact.name">
                                <div><i>Email: </i>{{ contact.accountsEmail }}</div>
                                <div><i>Phone: </i>{{ contact.phone }}</div>
                            </v-list-item>
                            <v-list-item v-if="name" title="Name" :subtitle="name"></v-list-item>
                            <v-list-item v-if="customerNumber" title="Customer Number" :subtitle="customerNumber"></v-list-item>
                            <v-list-item v-if="dateReceiveEstimate" title="Estimated Receive Date" :subtitle="$dayjs(dateReceiveEstimate).format('DD/MM/YYYY')"></v-list-item>
                            <v-list-item v-if="allChargesValid" :title="steps[1]" subtitle="Summary of charges">
                                <job-items-small :items="items"></job-items-small>
                            </v-list-item>
                            <v-list-item v-if="selectedTasks" :title="steps[2]" subtitle="Summary of tasks">
                                <v-table density="compact" class="small-table">
                                    <tbody>
                                        <tr v-for="task in selectedTasks" :key="task.type">
                                            <td><v-icon :icon="taskDetail(task.type).icon" class="mr-2"></v-icon> {{ taskDetail(task.type).title }}</td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-list-item>
                        </v-list>
                        <v-alert v-if="saveValid"
                                 type="success"
                                 title="Ready to Save"
                                 text="Job is ready to be saved. Click the save button below."
                                 class="my-2">
                        </v-alert>
                        <div class="mt-2 pt-2">
                            <v-btn :loading="loading"
                                   type="submit"
                                   :disabled="!saveValid"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-floppy">SAVE</v-btn>
                            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
    </v-form>
</template>
<style scoped>
    .small-table td, th {
        padding:0px!important;
        font-size:10px!important;
    }
    .charges-table td, th {
        padding: 0px 5px !important;
    }
    .charges-table .w-lg {
        width: 18%;
    }
    .charges-table .w-sm{
        width:8%;
    }
    .charges-table .w-xs {
        width:5%;
    }
    :deep(.v-field) {
        font-size: 0.875rem !important;
    }
    .total-line td {
        border-top: 2px solid black !important;
        border-bottom: 2px solid black;
    }
    .total-subline td {
        box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
    }
</style>
<script>
    import _cloneDeep from "lodash/cloneDeep";
    import { mapActions, mapState, mapGetters } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    import { numberWithCommas, roundAway2dp } from "../mixins/numberMixins";
    import tasks from "../constants/tasks";
    function initialState() {
        return {
            key: 0,
            saving: false,
            loading: false,
            name: "",
            code: "",
            valid: false,
            errors: null,
            step: 1,
            steps: ['Contact', 'Details', 'Charges', 'Tasks'],
            contactSearchTerm: "",
            contactSearching: false,
            addNewContactForm: false,
            contactSearchTimer: null,
            contactSearchResults: [],
            contact: null,
            lines: [],
            type: null,
            customerNumber: '',
            dateReceiveEstimate: null,
            pricingTimer: null
        };
    }
    export default {
        name: "JobForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.name = this.editEntity.name;
            }
            this.dateReceiveEstimate = this.$dayjs().format('YYYY-MM-DD');
            this.loadSelectColours();
            this.loadJobItemTemplates();
            // Add a line to charges
            this.addProductLine();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities",
                "loadContactProducts",
                "loadSelectColours",
                "loadJobItemTemplates"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                formData.append('name', this.name);
                formData.append('customerNumber', this.customerNumber);
                formData.append('dateReceiveEstimate', this.dateReceiveEstimate);
                formData.append('contactId', this.contact.id);
                // build job items
                let t = 0;
                this.lines.forEach(line => {
                    formData.append(`jobItems[${t}].order`, t.toString());
                    formData.append(`jobItems[${t}].quantity`, line.quantity);
                    formData.append(`jobItems[${t}].productId`, line.product.id);
                    if (line.colour) {
                        formData.append(`jobItems[${t}].colourId`, line.colour.id);
                    }
                    if (line.width) {
                        formData.append(`jobItems[${t}].width`, line.width);
                    }
                    if (line.height) {
                        formData.append(`jobItems[${t}].height`, line.height);
                    }
                    if (line.depth) {
                        formData.append(`jobItems[${t}].depth`, line.depth);
                    }
                    if (line.length) {
                        formData.append(`jobItems[${t}].length`, line.length);
                    }
                    // Load charges
                    let c = 0;
                    line.jobItemCharges.forEach(charge => {
                        formData.append(`jobItems[${t}].charges[${c}].jobItemTemplateId`, charge.jobItemTemplate.id);
                        formData.append(`jobItems[${t}].charges[${c}].unitPrice`, charge.unitPrice);
                        c++;
                    });
                    t++;
                });

                // build job charges
                let i = 0;
                this.selectedTasks.forEach(task => {
                    formData.append(`jobTasks[${i}].type`, task.type);
                    i++;
                });

                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Updated!");
                        this.reloadEntities();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            moveNext() {
                if (this.step == 1 && this.contact) {
                    // Load contact products
                    this.loadContactProducts(this.contact.id);
                }
                this.step++;
            },
            movePrevious() {
                this.step--;
            },
            showAddContact() {
                this.addNewContactForm = true;
                this.contactSearchTerm = '';
                this.contactSearching = false;
                this.contact = {
                    id: null,
                    name: '',
                    accountsEmail: '',
                    phone: '',
                    contactGroup: null,
                    contactPeople: []
                };
            },
            searchContacts() {
                this.contactSearching = true;
                this.$api.get(this.entityContact.ROUTE + "?searchTerm=" + this.contactSearchTerm)
                    .then(res => {
                        this.contactSearching = false;
                        this.contactSearchResults = res.data.items;
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.contactSearching = false;
                    });
            },
            selectContact(option) {
                if (this.contact == option) {
                    this.contact = null;
                } else {
                    this.contact = option;
                }
            },
            activeContact(option) {
                return this.contact != null && this.contact.id == option.id;
            },
            addProductLine() {
                this.lines.push({ 
                    quantity: 1, 
                    product: null, 
                    width: null,
                    height: null,
                    depth: null,
                    colour: null, 
                    jobItemCharges: [{ jobItemTemplate: null, unitPrice: null }], 
                    jobTasks: null});
            },
            addCharge(line) {
                line.jobItemCharges.push({ jobItemTemplate: null, unitPrice: null });
            },
            productsPricingItems(line) {
                if (line.product) {
                    return this.filterJobItemTemplates(line.product.productJobItems);
                }
                return [];
            },
            lineUnit(charge) {
                return numberWithCommas((charge.unitPrice ?? 0), 2);
            },
            lineTotal(line, charge) {
                return (charge.unitPrice ?? 0) * line.quantity;
            },
            lineChargeSubtotalTotal(line) {
                let result = 0;
                line.jobItemCharges.forEach(charge => {
                    result += this.lineTotal(line, charge);
                });
                return result;
            },
            lineUom(line) {
                if (line.product) {
                    return line.product.uomType;
                }
                return null;
            },
            lineFixedSize(line) {
                if (line.product) {
                    return line.product.fixedSize;
                }
                return false;
            },
            copyLine(line) {
                let newLine = _cloneDeep(line);
                this.lines.push(newLine);
            },
            deleteLine(index, chargeIndex) {
                if (chargeIndex > 0) {
                    this.lines[index].jobItemCharges.splice(chargeIndex, 1);
                } else {
                    this.lines.splice(index, 1);
                }
            },
            productChange(line) {
                this.loadLineUom(line);
                line.jobItemCharges = [{ jobItemTemplate: null, unitPrice: null }];
                line.colour = null;
            },
            loadLineUom(line) {
                if (this.lineFixedSize(line)) {
                    this.$api.get(this.$constants.entities.Product.ROUTE + "productuoms?productId=" + line.product.id)
                        .then(res => {
                            line.length = res.data.length;
                            line.width = res.data.width;
                            line.height = res.data.height;
                            line.depth = res.data.depth;
                        })
                        .catch(error => {
                            this.showErrorSnack("Could not load product uoms");
                        });
                }
            },
            jobItemChange(line, charge){
                if (!this.lineNeedsColour(line)){
                    line.colour = null;
                }
                this.loadLineUom(line);
                this.loadPricing(line, charge);
            },
            loadPricing(line, charge) {
                clearTimeout(this.pricingTimer);
                if (line.product && charge.jobItemTemplate && (!charge.jobItemTemplate.requiresColour || line.colour) &&
                    ((this.lineUom(line) == "Periphery Area" && line.width && line.height && line.depth) || (this.lineUom(line) != "Area" && line.length))) {
                        this.pricingTimer = setTimeout(() => {
                            let routeParams = [];
                            routeParams.push('contactId=' + this.contact.id);
                            routeParams.push('productId=' + line.product.id);
                            routeParams.push('jobItemTemplateId=' + charge.jobItemTemplate.id);
                            if (charge.jobItemTemplate.requiresColour) {
                                routeParams.push('colourId=' + line.colour.id);
                            }
                            if (line.width) {
                                routeParams.push('width=' + line.width);
                            }
                            if (line.height) {
                                routeParams.push('height=' + line.height);
                            }
                            if (line.depth) {
                                routeParams.push('depth=' + line.depth);
                            }
                            if (line.length) {
                                routeParams.push('length=' + line.length);
                            }
                            this.$api.get(this.$constants.entities.Job.ROUTE + "itempricing?" + routeParams.join('&'))
                                .then(res => {
                                    charge.unitPrice = res.data;
                                })
                                .catch(error => {
                                    if (error.response.data && Array.isArray(error.response.data)) {
                                        this.errors = error.response.data;
                                    } else {
                                        this.errors = { title: "Whoops", errors: [["There was an issue calculating pricing."]] };
                                    }
                                });
                        }, 1500);
                }
            },
            numberWithCommas,
            taskDetail(type){
                if (typeof tasks.types[type] === 'undefined')
                    return null;
                return tasks.types[type];
            },
            toggleItemTask(task){
                if (!task.mandatory){
                    task.selected = !task.selected;
                }
            },
            lineNeedsColour(line) {
                return line.jobItemCharges.filter(charge => {
                    return charge.jobItemTemplate && charge.jobItemTemplate.requiresColour;
                }).length > 0;
            }
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
                contactProducts: (state) => state.products.contactProducts,
                contactProductsLoading: (state) => state.products.contactProductsLoading,
                coloursSelect: (state) => state.colours.coloursSelect,
                coloursSelectLoading: (state) => state.colours.coloursSelectLoading
            }),
            ...mapGetters([
                "filterJobItemTemplates",
                "templateJobItemTasks"
            ]),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            entityContact() {
                return this.$constants.entities.Contact;
            },
            stepperActionsDisabled() {
                let prevDisabled = this.step == 1;
                let nextDisabled = this.step == this.steps.length;
                // Stepper action rules
                if (this.step == 1 && !this.contact) {
                    nextDisabled = true;
                }
                if (this.step == 3 && !this.allChargesValid){
                    nextDisabled = true;
                }
                if (prevDisabled && nextDisabled) {
                    return true;
                } else if (nextDisabled) {
                    return 'next';
                } else if (prevDisabled) {
                    return 'prev';
                }
                return false;
            },
            saveValid() {
                return this.allChargesValid && this.selectedTasks.length > 0 && this.contact;
            },
            chargeTaxTotals() {
                return roundAway2dp(this.chargeSubTotals * 0.15);
            },
            chargeTotals() {
                return this.chargeSubTotals + this.chargeTaxTotals;
            },
            chargeSubTotals() {
                let result = 0;
                this.lines.forEach(line => {
                    line.jobItemCharges.forEach(charge => {
                        if (charge.unitPrice && line.quantity) {
                            result += charge.unitPrice * line.quantity;
                        }
                    });
                });
                return result;
            },
            quantityTotal(){
                let result = 0;
                this.lines.forEach(line => {
                    if (line.quantity) {
                        result += line.quantity;
                    }
                });
                return result;
            },
            allChargesValid() {
                let valid = true;
                this.lines.forEach(line => {
                    let lineTotal = 0;
                    line.jobItemCharges.forEach(charge => {
                        if (!charge.unitPrice) {
                            valid = false;
                            return false;
                        }
                        lineTotal += charge.unitPrice * line.quantity;
                    });
                    if (!line.jobItemCharges || !line.product || !line.quantity || line.job || lineTotal == 0){
                        valid = false;
                        return false;
                    }
                });
                return valid;
            },
            lineTemplateTasks() {
                let result = [];
                this.lines.forEach(line => {
                    line.jobItemCharges.forEach(charge => {
                        if (charge.jobItemTemplate) {
                            charge.jobItemTemplate.jobTaskTemplates.forEach(item => {
                                const i = result.findIndex(e => e.type === item.type);
                                if (i > -1) {
                                    if (!result[i].mandatory && item.mandatory) {
                                        result[i].mandatory = item.mandatory;
                                    }
                                } else {
                                    result.push(item);
                                }
                            });

                        }
                    });
                });
                return result.sort((a, b) => a.type - b.type);
            },
            selectedTasks() {
                if (this.lineTemplateTasks){
                    return this.lineTemplateTasks.filter(item => {
                        return item.selected;
                    });
                }
                return [];
            }
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.contactSearchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        this.contactSearchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },

        }
    };
</script>