<template>
    <div class="buttons">
        <div class="button-controls d-flex justify-space-between">
            <v-btn prepend-icon="mdi-printer" @click="printPage">Print</v-btn>
            <div class="w-25">
                <v-text-field label="Program Date"
                              type="date"
                              density="compact"
                              @change="loadData"
                              :hide-details="true"
                              v-model="date"></v-text-field>
            </div>
        </div>
    </div>
    <div class="book">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <div v-if="!loading && data">
            <div v-for="(list, index) in data.lists" :key="list.name">
                <div v-if="list.jobs && list.jobs.length > 0" class="page">
                    <div class="subpage">
                        <div>
                            <div class="d-flex justify-space-between">
                                <div>
                                    <h2><small>{{ list.name }} Program -</small> {{ data.date }}</h2>
                                </div>
                                <div>
                                    <v-img height="50"
                                           width="50"
                                           style="background:black;"
                                           src="/assets/logo.png"></v-img>
                                </div>
                            </div>
                            <div class="mb-3">
                                <table v-if="index < 2" class="job-table">
                                    <thead class="header-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style="width:8%">#</th>
                                            <th style="width:20%">Customer</th>
                                            <th style="width:20%">Colour</th>
                                            <th style="width:20%">Cust#</th>
                                            <th style="width:8%" class="text-right">Est. Time</th>
                                            <th style="width:8%" class="text-right">Powder</th>
                                            <th style="width:8%">Start Time</th>
                                            <th style="width:8%">End Time</th>
                                        </tr>
                                    </tbody>
                                    <tbody v-for="group in groupedJobs(list.jobs)" :key="group.colours">
                                        <tr class="name-row">
                                            <td colspan="3"><b>{{ group.colours }}</b></td>
                                            <td></td>
                                            <td class="text-right">
                                                <b>{{ timeEstimate(taskStatusTotal(group.jobs), list.jobs) }}</b>
                                            </td>
                                            <td class="text-right">
                                                <b>{{ numberWithCommas(totalPowder(group.jobs), 2)}}</b>
                                            </td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr v-for="job in group.jobs" :key="job.id" :class="getTagClass(job.jobTag)">
                                            <td>
                                                {{ job.name }} <entity-tag-chip :object="{entityType:'Job', jobTag:job.jobTag }"></entity-tag-chip>
                                            </td>
                                            <td><span v-if="job.contact">{{ job.contact.name }}</span></td>
                                            <td><span v-if="job.colours">{{ job.colours }}</span></td>
                                            <td>{{ job.customerNumber }}</td>
                                            <td class="text-right">{{ timeEstimate(job.taskSubTotal, list.jobs) }}</td>
                                            <td class="text-right"><span v-if="job.jobColours.length == 1">{{ numberWithCommas(job.jobColours[0].powderRequired, 2) }}</span></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="footer-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <table v-if="index > 1" class="job-table">
                                    <thead class="header-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="w-10">#</th>
                                            <th class="w-20">Customer</th>
                                            <th class="w-20">Colour</th>
                                            <th class="w-20">Cust#</th>
                                            <th class="w-30">Comments</th>
                                        </tr>
                                        <tr v-for="job in list.jobs" :key="job.id" :class="getTagClass(job.jobTag)">
                                            <td>
                                                {{ job.name }}
                                                <entity-tag-chip :object="{entityType:'Job', jobTag:job.jobTag }"></entity-tag-chip>
                                            </td>
                                            <td><span v-if="job.contact">{{ job.contact.name }}</span></td>
                                            <td><span v-if="job.colours">{{ job.colours }}</span></td>
                                            <td>{{ job.customerNumber }}</td>
                                            <td>
                                                <v-textarea variant="plain"
                                                            v-model="job.items"
                                                            class="small-field"></v-textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="footer-repeat">
                                        <tr>
                                            <td colspan="5" class="no-border"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from "vuex";
    import { getTagClass } from '../mixins/jobMixins';
    import { numberWithCommas, convertMinutesToHoursMinutes } from "../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: null,
            date: null
        };
    }
    export default {
        name: "DayProgramView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.date = this.$dayjs().format('YYYY-MM-DD');
            this.loadData();
        },
        props: {
        },
        methods: {
            numberWithCommas,
            getTagClass,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            printPage() {
                var css = '@page { size: A4 landscape!important; }',
                    head = document.head || document.getElementsByTagName('head')[0],
                    style = document.createElement('style');

                style.type = 'text/css';
                style.media = 'print';

                if (style.styleSheet){
                  style.styleSheet.cssText = css;
                } else {
                  style.appendChild(document.createTextNode(css));
                }

                head.appendChild(style);
                window.print();
            },
            loadData() {
                this.loading = true;
                this.$api.get('dashboard/dayprogram?date='+this.date)
                    .then(res => {
                        this.data = res.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.showErrorSnack('There was an issue getting the jobs');
                    });
            },
            timeEstimate(total, jobs) {
                let statusTotal = this.taskStatusTotal(jobs);
                if (statusTotal == 0) {
                    return 0;
                }
                return convertMinutesToHoursMinutes((total / statusTotal) * 480); // value * minutes
            },
            totalPowder(jobs) {
                if (jobs && jobs.length > 0) {
                    return jobs.reduce((acc, job) => {
                        if (job.jobColours.length == 1) {
                            return acc + job.jobColours[0].powderRequired;
                        }
                    }, 0);
                }
                return 0;
            },
            taskStatusTotal(jobs) {
                if (jobs && jobs.length > 0) {
                    return jobs.reduce((acc, obj) => acc + obj.taskSubTotal, 0);
                }
                return 0;
            },
            groupedJobs(jobs) {
                return  jobs.reduce((acc, job) => {
                    // Find the existing group by colour
                    const existingGroup = acc.find(group => group.colours === job.colours);

                    if (existingGroup) {
                        // If the group exists, add the job to it
                        existingGroup.jobs.push(job);
                    } else {
                        // If it doesn't exist, create a new group
                        acc.push({
                            colours: job.colours,
                            jobs: [job]
                        });
                    }

                    return acc;
                }, []);
            },
        },
        computed: {
        }
    };
</script>
<style scoped lang="scss">
    @import '../styles/landscapeprint.scss';
</style>