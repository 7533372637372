<template>
    <v-dialog v-model="showJobCompleteDialog"
            persistent
            :min-width="$windowWidth < 1200 ? '60%' : '40%'"
            min-height="80%"
            width="auto">
        <v-card>
            <v-toolbar theme="dark">
                <v-toolbar-title>Confirm Completion of {{ dialogJob.name }}</v-toolbar-title>
                <v-btn icon="mdi-close"
                       @click="showJobCompleteDialog = false"></v-btn>
            </v-toolbar>
            <v-card-text>
                <span v-if="!partiallyCompleted">Are you sure this job has been completed?</span>
                <div v-if="partiallyCompleted">
                    <p class="mb-2">This will mark the job as partially completed please indicate below the percentage of the job that has been completed and the date you will be rescheduling the remaining for.</p>
                    <v-text-field label="Reschedule Date"
                                  type="date"
                                  :hide-details="true"
                                  v-model="rescheduleDate"></v-text-field>
                    <v-text-field label="Percentage of job completed"
                                  v-model.number="percentageCompleted"
                                  type="number"
                                  suffix="%"></v-text-field>
                    <div>
                        Job currently has an outstanding amount of ${{ numberWithCommas(dialogJob.taskSubTotal, 2) }}.
                    </div>
                    <div v-if="percentageCompleted">
                        <h2><small>Estimated completed amount </small> ${{ numberWithCommas(estimatedCompleteAmount, 2) }}</h2>
                    </div>
                </div>
                <v-alert icon="mdi-alert-decagram-outline"
                         class="my-2"
                         v-if="completeErrors.length > 0"
                         type="warning">
                    <ul class="small-text">
                        <li :key="error" v-for="error in completeErrors">{{ error }}</li>
                    </ul>
                </v-alert>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="saveCompleted"
                       :loading="saving"
                       :disabled="completeErrors.length > 0"
                       prepend-icon="mdi-check-circle"
                       color="success">
                    SAVE COMPLETED
                </v-btn>
                <v-btn variant="tonal" @click="showJobCompleteDialog = false" :disabled="saving">
                    CANCEL
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss'
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../../../mixins/searchMixins";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            percentageCompleted: null,
            rescheduleDate: null
        };
    }
    export default {
        name: "SmartJobCompleteDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object,
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "loadSmartData"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            numberWithCommas,
            resetInput() {
                this.rescheduleDate = null;
                this.percentageCompleted = null;
                if (this.dialogJob && this.dialogJob.taskDate){
                     let currentDate = this.$dayjs(this.dialogJob.taskDate).add(1, 'day');
                     this.rescheduleDate = (currentDate.day() === 6
                         ? currentDate.add(2, 'day')  // Subtract 3 days for Monday -> Friday
                         : currentDate.day() === 0 ? currentDate.add(1, 'day') : currentDate).format('YYYY-MM-DD');
                }
            },
            saveCompleted() {
                this.savingCompleted = true;
                let formData = new FormData();
                formData.append('id', this.dialogJob.jobId);
                formData.append('taskId', this.dialogJob.jobTaskId);
                formData.append('partiallyCompleted', this.partiallyCompleted)
                if (this.partiallyCompleted) {
                    formData.append('rescheduleDate', this.rescheduleDate);
                    formData.append('estimatedCompleteAmount', this.estimatedCompleteAmount);
                }
                formData.append('startDate', this.smartStartDate);
                formData.append('endDate', this.smartEndDate);
                this.$api.post(this.$constants.entities.Job.ROUTE + 'completejob', formData)
                    .then(res => {
                        this.loadSmartData(res.data);
                    })
                    .catch(error => {
                        this.showErrorSnack('There was an issue completing the job');
                    });
            }
        },
        computed: {
            ...mapState({
                dialogJob: (state) => state.smart.dialogJob,
                smartStartDate: (state) => state.smart.smartStartDate,
                smartEndDate: (state) => state.smart.smartEndDate,
                partiallyCompleted: (state) => state.smart.partiallyCompleted,
            }),
            showJobCompleteDialog: {
                get() {
                    return this.$store.state.smart.showJobCompleteDialog
                },
                set(value) {
                    this.$store.commit('setShowJobCompleteDialog', value)
                }
            },
            estimatedCompleteAmount() {
                if (this.dialogJob && this.percentageCompleted && !isNaN(parseFloat(this.percentageCompleted))) {
                    return this.dialogJob.taskSubTotal * (parseFloat(this.percentageCompleted) / 100)
                }
                return null;
            },
            completeErrors() {
                let errors = [];
                if (this.partiallyCompleted) {
                    // Must have percentage and reschedule date
                    if (!this.rescheduleDate || this.rescheduleDate.length == 0) {
                        errors.push('Must have reschedule date');
                    }
                    if (!this.percentageCompleted || this.percentageCompleted.length == 0) {
                        errors.push('Must have a percentage completed to continue');
                    }
                    if (this.estimatedCompleteAmount && this.job && this.estimatedCompleteAmount >= this.dialogJob.taskSubTotal) {
                        errors.push('Untick partially completed if you have completed the whole job')
                    }
                }
                return errors;
            },
        },
        watch: {
            dialogJob: {
                handler: function () {
                    if (this.dialogJob) {
                        this.resetInput();
                    }
                }
            }
        }
    };
</script>