<template>
    <v-dialog v-model="showJobDialog"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            :fullscreen="$windowWidth < 960"
            min-width="80%"
            min-height="80%"
            width="auto">
        <v-card v-if="dialogJob">
            <v-toolbar theme="dark">
                <v-toolbar-title>{{ dialogJob.name }} <span v-if="dialogJob.customerNumber"> - {{ dialogJob.customerNumber }}</span></v-toolbar-title>
                <v-btn icon="mdi-close"
                       @click="showJobDialog = false"></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="8" md="9">
                        <v-row>
                            <v-col cols="12" md="6">
                                <table class="detail-table w-100">
                                    <tr v-if="dialogJob.contact">
                                        <td>
                                            <contact-link :contact="dialogJob.contact"></contact-link>
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.customerNumber">
                                        <td>
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-pound-box-outline"></v-icon> Customer#</small><br />{{ dialogJob.customerNumber }}
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.dateReceived">
                                        <td>
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-calendar-range"></v-icon> Receive Date</small><br /><date-ago-display :date="dialogJob.dateReceived" :days-ago="dialogJob.daysSinceReceived" :countdown="false"></date-ago-display>
                                        </td>
                                    </tr>
                                </table>
                                <table v-if="dialogJob.jobColours" class="w-100 detail-table text-caption mt-2">
                                    <tr>
                                        <th class="text-left">Colour</th>
                                        <th class="text-right">Coating</th>
                                        <th class="text-right">Powder</th>
                                    </tr>
                                    <tr v-for="colour in dialogJob.jobColours" :key="colour.colour">
                                        <td class="text-left">{{ colour.name }}</td>
                                        <td class="text-right">{{ numberWithCommas(colour.estimateCoatingTime, 0) }} mins</td>
                                        <td class="text-right">{{ numberWithCommas(colour.powderRequired, 0) }} Kgs</td>
                                    </tr>
                                </table>
                                <table v-if="dialogJob.jobItems" class="w-100 detail-table text-caption mt-2">
                                    <tr>
                                        <th class="text-left">Description</th>
                                        <th class="text-right">Quantity</th>
                                    </tr>
                                    <tr v-for="item in dialogJob.jobItems" :key="item.id">
                                        <td class="text-left">{{ item.description }}</td>
                                        <td class="text-right">{{ numberWithCommas(item.quantity, 0) }}</td>
                                    </tr>
                                </table>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Target Completion Date"
                                              type="date"
                                              density="compact"
                                              :hide-details="true"
                                              @change="updateSmartJob({ path:'dateTargetCompletion', value:dialogJob.targetDate})"
                                              v-model="dialogJob.targetDate"></v-text-field>
                                <v-checkbox :model-value="dialogJob.datePretreatment != null"
                                            label="Pretreatment Complete?"
                                            @change="updateSmartPretreated"
                                            :hide-details="true"
                                            density="compact"></v-checkbox>
                                <v-checkbox :model-value="dialogJob.dateReceived != null"
                                            label="Material Received?"
                                            @change="updateSmartReceived"
                                            :hide-details="true"
                                            density="compact"></v-checkbox>
                                <v-select v-model="dialogJob.jobTag"
                                          label="Job Tag"
                                          density="compact"
                                          :clearable="true"
                                          @update:modelValue="updateSmartJob({ path:'jobTag', value:dialogJob.jobTag})"
                                          :items="jobTags">
                                </v-select>
                                <table class="detail-table w-100">
                                    <tr>
                                        <td>
                                            <b>Job Status</b> <type-chip :type="dialogJob.jobStatus" entity="Job" :object="job"></type-chip>
                                            <v-chip v-if="sprayingCompleted" color="light-green-darken-1" class="ml-1"><v-icon start icon="mdi-marker-check"></v-icon> SPRAYING COMPLETED</v-chip>
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.activeTask && (dialogJob.activeTask.status == $constants.jobs.JOBSTAT_BLASTING || dialogJob.activeTask.status == $constants.jobs.JOBSTAT_STRIPPING)">
                                        <td>
                                            <job-task-buttons-timer :job="dialogJob"></job-task-buttons-timer>
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.totalLineTime && dialogJob.totalLineTime > 0">
                                        <td>
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-timeline-clock-outline"></v-icon> Line Time</small><br />
                                            <span>{{ dialogJob.totalLineTime }} minutes</span>
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.outstandingSubtotal != dialogJob.jobSubTotal && dialogJob.outstandingSubtotal != 0">
                                        <td>
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-filter-variant"></v-icon> Oustanding</small><br />
                                            ${{ numberWithCommas(dialogJob.outstandingSubtotal, 2) }}
                                            <v-chip v-if="remainingPercentage"
                                                    :prepend-icon="remainingPercentage > 0 ? 'mdi-list-status' : 'mdi-check-circle'"
                                                    size="small"
                                                    :color="remainingPercentage > 0 ? 'warning' : 'success'">{{ numberWithCommas(remainingPercentage, 0) }}%</v-chip>
                                        </td>
                                    </tr>
                                    <tr v-if="dialogJob.activeTask && dialogJob.activeTask.assignedLocation">
                                        <td>
                                            <small class="text-grey-darken-2"><v-icon icon="mdi-map-marker-radius"></v-icon> Location</small><br />
                                            <span>{{ dialogJob.activeTask.assignedLocation }}</span>
                                        </td>
                                    </tr>
                                </table>
                                <div v-if="dialogJob.jobItemTasks && dialogJob.jobItemTasks.length > 0">
                                    <table class="w-100 detail-table text-caption my-2 task-item-table">
                                        <tr>
                                            <th class="w-30 text-left">Task Item</th>
                                            <th class="w-40 text-right">Quantity</th>
                                        </tr>
                                        <tr v-for="taskItem in dialogJob.jobItemTasks" :key="taskItem.jobTaskId">
                                            <td>{{ taskItem.itemDescription }}</td>
                                            <td class="text-right">{{ taskItem.quantity }}</td>
                                        </tr>
                                    </table>
                                </div>

                                <table v-if="dialogJob.archivedJobTasks && dialogJob.archivedJobTasks.length > 0" class="w-100 detail-table text-caption mt-2">
                                    <tr>
                                        <th class="w-30 text-left">Task</th>
                                        <th class="w-40 text-right">Date</th>
                                        <th class="w-30 text-right">Value</th>
                                    </tr>
                                    <tr v-for="archivedTask in dialogJob.archivedJobTasks" :key="archivedTask.id">
                                        <td>{{ archivedTask.name }}</td>
                                        <td class="text-right"><span v-if="archivedTask.targetStartDate">{{ $dayjs(archivedTask.targetStartDate).format('DD-MM-YYYY') }}</span></td>
                                        <td class="text-right"><span v-if="archivedTask.estimatedSubtotalCompleted">${{ numberWithCommas(archivedTask.estimatedSubtotalCompleted, 2) }}</span></td>
                                    </tr>
                                </table>
                                <table class="detail-table w-100 mt-2">
                                    <tr>
                                        <td class="text-right">
                                            <h2 v-if="dialogJob.jobSubTotal != dialogJob.taskSubTotal" class="job-total-block">
                                                <small class="text-grey-darken-2 float-left">Completed</small>
                                                ${{ numberWithCommas(dialogJob.taskSubTotal, 2) }}
                                            </h2>
                                            <h2>
                                                <small class="text-grey-darken-2 float-left">Job Total</small>
                                                ${{ numberWithCommas(dialogJob.jobSubTotal, 2) }}
                                            </h2>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4" md="3" class="smart-note-section">
                        <note-bar></note-bar>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
                <div>
                    <v-btn :to="'/'+$constants.entities.Job.ROUTE+dialogJob.jobId"
                           @click.stop
                           variant="tonal"
                           prepend-icon="mdi-arrow-bottom-right-thin-circle-outline"
                           color="primary">
                        Go to Job
                    </v-btn>
                    <v-btn :to="'/'+$constants.entities.Job.ROUTE+dialogJob.jobId+'/jobsheet/'+dialogJob.jobTaskId"
                           variant="outlined" 
                           color="primary" 
                           v-if="true"
                           prepend-icon="mdi-printer" 
                           class="mr-1" 
                           @click.stop>
                        Job Card
                    </v-btn>
                </div>
                <div>
                    <v-btn @click="openSmartJobReworkDialog"
                           v-if="dialogJob.jobStatus != $constants.jobs.JOBSTAT_SCHEDULE"
                           class="ml-1 mb-1"
                           prepend-icon="mdi-recycle"
                           variant="tonal"
                           color="red-darken-1">Rework</v-btn>

                    <v-btn @click="openSmartJobCompletePartialDialog"
                           class="ml-1 mb-1"
                           v-if="dialogJob.jobStatus != $constants.jobs.JOBSTAT_SCHEDULE && dialogJob.jobStatus < $constants.jobs.JOBSTAT_INVOICE && !dialogJob.archived"
                           prepend-icon="mdi-progress-check"
                           variant="tonal"
                           color="success">Partially Complete</v-btn>

                    <v-btn @click="openSmartJobCompleteDialog"
                           class="ml-1 mb-1"
                           v-if="dialogJob.jobStatus != $constants.jobs.JOBSTAT_SCHEDULE && dialogJob.jobStatus < $constants.jobs.JOBSTAT_INVOICE && !dialogJob.archived"
                           prepend-icon="mdi-check-circle"
                           variant="tonal"
                           color="success">Complete</v-btn>

                    <v-btn v-if="dialogJob.jobStatus >= $constants.jobs.JOBSTAT_INVOICE"
                           prepend-icon="mdi-check-circle"
                           class="ml-1 mb-1"
                           color="secondary"
                           variant="tonal"
                           disabled>Completed</v-btn>

                    <v-btn v-if="dialogJob.jobStatus == $constants.jobs.JOBSTAT_INVOICE"
                           prepend-icon="mdi-invoice-text-outline"
                           class="ml-1 mb-1"
                           variant="tonal"
                           color="info"
                           @click="updateSmartJobStatus({ job: dialogJob, status: $constants.jobs.JOBSTAT_COMP})">Invoiced</v-btn>

                    <v-btn v-if="dialogJob.jobStatus == $constants.jobs.JOBSTAT_COMP"
                           prepend-icon="mdi-check-circle"
                           class="ml-1 mb-1"
                           color="secondary"
                           variant="tonal"
                           disabled>Job Invoiced</v-btn>
                    <v-btn @click="showJobDialog = false" variant="tonal" class="ml-1 mb-1">Close</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss';
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../../../mixins/searchMixins";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "SmartJobDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object,
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "updateSmartJob",
                "updateSmartJobStatus",
                "openSmartJobCompleteDialog",
                "openSmartJobCompletePartialDialog",
                "openSmartJobReworkDialog",
                "openSmartPretreated",
                "saveSmartPretreated",
                "updateSmartReceived"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            numberWithCommas,
            updateSmartPretreated() {
                if (this.dialogJob.datePretreatment) {
                    // Remove pretreated
                    this.$confirm(
                        {
                            message: 'Are you sure you want to set job as NOT pretreated?',
                            disableKeys: false,
                            auth: false,
                            button: {
                                no: 'No',
                                yes: 'Yes'
                            },
                            callback: confirm => {
                                if (confirm) {
                                    this.saveSmartPretreated({ job: this.dialogJob, date: '', location: '', supervisor: '' });
                                }
                            }
                        }
                    )
                } else {
                    // Add pretreated
                    this.openSmartPretreated(this.dialogJob);
                }
            },
        },
        computed: {
            ...mapState({
                dialogJob: (state) => state.smart.dialogJob,
            }),
            showJobDialog: {
                get() {
                    return this.$store.state.smart.showJobDialog
                },
                set(value) {
                    this.$store.commit('setShowJobDialog', value)
                }
            },
            jobTags() {
                return this.$constants.jobs.tags.map(item => item.name);
            },
            remainingPercentage() {
                if (this.dialogJob) {
                    return (this.dialogJob.outstandingSubtotal / this.dialogJob.jobSubTotal) * 100;
                }
                return null;
            },
            sprayingCompleted() {
                if (this.dialogJob) {
                    return (this.dialogJob.activeTask.status == this.$constants.jobs.JOBSTAT_LINE1 || this.dialogJob.activeTask.status == this.$constants.jobs.JOBSTAT_LINE2) && this.dialogJob.activeTask.endTime;
                }
                return false;
            }
        }
    };
</script>