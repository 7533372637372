<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-row class="pt-3">
            <v-col md="4">
                <v-card color="indigo-accent-2" variant="flat" class="text-center">
                    <template v-slot:subtitle>
                        <div class="d-flex justify-space-between">
                            <div>
                                TODAY
                                <v-chip v-if="dashData" variant="flat" size="small" prepend-icon="mdi-bullseye-arrow" class="ml-1">${{ numberWithCommas(dashData.dayTarget, 2) }}</v-chip>
                            </div>
                            <h5 v-if="dashData">{{ dashData.todayDate }} & {{ dashData.lastInvoiceDate }}</h5>
                        </div>  
                    </template>
                    <template v-slot:text>
                        <v-skeleton-loader v-if="loading" type="card-avatar"></v-skeleton-loader>
                        <div v-if="!loading">
                            <div class="card-legend mt-0">RECEIVED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.todayJobTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.todayJobTotal, dashData.dayTarget)"
                                          color="indigo-darken-4"></progress-bar>
                            <div class="card-legend">INVOICED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.todayInvoiceTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.todayInvoiceTotal, dashData.dayTarget)"
                                          color="indigo-darken-4"></progress-bar>
                            <div class="card-legend">PRODUCTION</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.todayProductionTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.todayProductionTotal, dashData.dayTarget)"
                                          color="indigo-darken-4"></progress-bar>
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card color="cyan-darken-1" variant="flat" class="text-center">
                    <template v-slot:subtitle>
                        <div class="d-flex justify-space-between">
                            <div>
                                WEEK
                                <v-chip v-if="dashData" variant="flat" size="small" prepend-icon="mdi-bullseye-arrow" class="ml-1">${{ numberWithCommas(dashData.weekTarget, 2) }}</v-chip>
                            </div>
                            <h5 v-if="dashData">{{ dashData.weekDate }}</h5>
                        </div>
                    </template>
                    <template v-slot:text>
                        <v-skeleton-loader v-if="loading" type="card-avatar"></v-skeleton-loader>
                        <div v-if="!loading">
                            <div class="card-legend mt-0">RECEIVED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.weekJobTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.weekJobTotal, dashData.weekTarget)"
                                          color="cyan-darken-4"></progress-bar>
                            <div class="card-legend">INVOICED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.weekInvoiceTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.weekInvoiceTotal, dashData.weekTarget)"
                                          color="cyan-darken-4"></progress-bar>
                            <div class="card-legend">PRODUCTION</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.weekProductionTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.weekProductionTotal, dashData.weekTarget)"
                                          color="cyan-darken-4"></progress-bar>
                            
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card color="blue-grey-darken-1" variant="flat" class="text-center">
                    <template v-slot:subtitle>
                        <div class="d-flex justify-space-between">
                            <div>
                                MONTH
                                <v-chip v-if="dashData" variant="flat" size="small" prepend-icon="mdi-bullseye-arrow" class="ml-1">${{ numberWithCommas(dashData.monthTarget, 2) }}</v-chip>
                            </div>
                            <h5 v-if="dashData">{{ dashData.monthDate }}</h5>
                        </div>
                    </template>
                    <template v-slot:text>
                        <v-skeleton-loader v-if="loading" type="card-avatar"></v-skeleton-loader>
                        <div v-if="!loading">
                            <div class="card-legend mt-0">RECEIVED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.monthJobTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.monthJobTotal, dashData.monthTarget)"
                                          color="cyan-darken-4"></progress-bar>
                            <div class="card-legend">INVOICED</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.monthInvoiceTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.monthInvoiceTotal, dashData.monthTarget)"
                                          color="cyan-darken-4"></progress-bar>
                            <div class="card-legend">PRODUCTION</div>
                            <h1 v-if="dashData" class="mt-2">${{ numberWithCommas(dashData.monthProductionTotal, 2) }}</h1>
                            <progress-bar v-if="dashData"
                                          :value="getTargetPercentage(dashData.monthProductionTotal, dashData.monthTarget)"
                                          color="cyan-darken-4"></progress-bar>
                        </div>
                    </template>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="3">
                <v-card variant="flat" color="amber-lighten-4" class="mb-3">
                    <template v-slot:subtitle>
                        <div class="text-center">WIP <v-chip v-if="dashData" size="small" variant="flat" prepend-icon="mdi-bullseye-arrow" class="ml-1">${{ numberWithCommas(dashData.wipTarget, 2) }}</v-chip></div>
                    </template>
                    <h1 class="text-center" v-if="dashData">${{ numberWithCommas(dashData.currentWIP, 2) }}</h1>
                    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
                    <div v-if="!loading" style="max-height:250px;overflow-y:auto;">
                        <top-customers-table v-if="dashData" :data="dashData.wipCustomers"></top-customers-table>
                    </div>
                    <v-divider></v-divider>
                    <div v-if="dashData && dashData.extendedWIP" class="py-2">
                        <h4 class="text-center"><small>EXTENDED</small></h4>
                        <h3 class="text-center" v-if="dashData">${{ numberWithCommas(dashData.extendedWIP, 2) }}</h3>
                    </div>
                    <div v-if="!loading" style="max-height:250px;overflow-y:auto;">
                        <top-customers-table v-if="dashData" :data="dashData.extendedWIPCustomers"></top-customers-table>
                    </div>
                </v-card>
                <v-card variant="flat" class="mb-3">
                    <template v-slot:subtitle>
                        MONTH'S TOP CUSTOMERS
                    </template>
                    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
                    <div v-if="!loading" style="height: 300px; width: 100%; overflow-y: auto;">
                        <top-customers-table v-if="dashData" :data="dashData.monthsTopContacts"></top-customers-table>
                    </div>
                </v-card>
                <v-card variant="flat">
                    <template v-slot:subtitle>
                        YEARS TOP CUSTOMERS
                    </template>
                    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
                    <div v-if="!loading" style="height: 300px; width: 100%; overflow-y: auto;">
                        <top-customers-table v-if="dashData" :data="dashData.yearsTopContacts"></top-customers-table>
                    </div>
                </v-card>
            </v-col>
            <v-col md="9">
                <v-card variant="flat">
                    <v-skeleton-loader :loading="loading" type="card">
                        <div style="height:500px; width:100%;" class="pa-3">
                            <Bar ref="bar" v-if="loaded" :data="graphData" :options="graphOptions" />
                        </div>
                        <div class="w-100">
                            <v-table v-if="dashData" density="compact" class="small-table">
                                <thead>
                                    <tr class="bg-blue-grey-lighten-5">
                                        <th class="w-25">Date</th>
                                        <th v-for="summary in dashData.invoicedSummary" :key="summary.date" class="text-right">{{ summary.date }}</th>
                                        <th class="text-right">TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody v-for="summaryHeader in weeklySummaryFields" :key="summaryHeader.header">
                                    <tr v-if="!summaryHeader.hideBlank || jobListTotal(dashData.invoicedSummary, summaryHeader.field) != 0">
                                        <td><strong>{{ summaryHeader.header }}</strong></td>
                                        <td v-for="summary in dashData.invoicedSummary" :key="summary.date" class="text-right">
                                            <span v-if="summaryHeader.type == 'currency' || summaryHeader.type == 'decimal'">
                                                <a v-if="summaryHeader.showJobs" 
                                                   href="javascript:;" 
                                                   @click="setDayJobs(summaryHeader.header, summary)">
                                                    <span v-if="summaryHeader.type == 'currency'">$</span>{{ numberWithCommas(summary[summaryHeader.field], 2) }}
                                                </a>
                                                <span v-if="!summaryHeader.showJobs"><span v-if="summaryHeader.type == 'currency'">$</span>{{ numberWithCommas(summary[summaryHeader.field], 2) }}</span>
                                            </span>
                                            <span v-if="summaryHeader.type == 'percentagetarget'">
                                                {{ numberWithCommas(getTargetPercentage(summary[summaryHeader.field], dashData.dayTarget, true), 1) }}%
                                            </span>
                                        </td>
                                        <td class="text-right">
                                            <strong>
                                                <span v-if="(summaryHeader.type == 'currency' && summaryHeader.field != 'revenueHours') || summaryHeader.type == 'decimal'">
                                                    <span v-if="summaryHeader.type == 'currency'">$</span>{{ jobListTotal(dashData.invoicedSummary, summaryHeader.field) }}
                                                </span>
                                                <span v-if="summaryHeader.field == 'revenueHours'">
                                                    ${{ numberWithCommas(jobListTotalSub(dashData.invoicedSummary, 'totalInvoiced')/jobListTotalSub(dashData.invoicedSummary, 'totalHoursWorked'), 2) }}
                                                </span>
                                                <span v-if="summaryHeader.type == 'percentagetarget'">
                                                    {{ numberWithCommas(getTargetPercentage(jobListTotalSub(dashData.invoicedSummary, summaryHeader.field), (dashData.dayTarget*5), true), 1) }}%
                                                </span>
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <v-table v-if="dashData" density="compact" class="small-table mt-4">
                                <thead>
                                    <tr class="bg-blue-grey-lighten-5">
                                        <th class="text-left w-10 border-right">
                                            Month
                                        </th>
                                        <th class="text-right w-7">Rec #</th>
                                        <th class="text-right w-7">
                                            Rec Total
                                        </th>
                                        <th class="text-right w-7 border-right">
                                            Rec AVG
                                        </th>
                                        <th class="text-right w-7">Inv #</th>
                                        <th class="text-right w-7">
                                            Inv Total
                                        </th>
                                        <th class="text-right w-7 border-right">
                                            Inv AVG
                                        </th>
                                        <th class="text-right w-7">Prod #</th>
                                        <th class="text-right w-7">
                                            Prod Total
                                        </th>
                                        <th class="text-right w-7 border-right">
                                            Prod AVG
                                        </th>
                                        <th class="text-right w-7">
                                            Hours
                                        </th>
                                        <th class="text-right w-7">
                                            R/Hr
                                        </th>
                                        <th class="text-right w-7">
                                            R/W
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="processMonth in dashData.dayTotalMonths" :key="processMonth">
                                    <tr>
                                        <td class="border-right">
                                            <h4>{{ processMonth }} <v-chip size="x-small" variant="flat" class="ml-1">{{ jobListWorkingOnly(monthDayTotals(processMonth)).length }} Days</v-chip></h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>{{ jobNumberTotal(monthDayTotals(processMonth), 'receivedJobs') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>${{ jobListTotal(monthDayTotals(processMonth), 'receivedTotal') }}</h4>
                                        </td>
                                        <td class="text-right border-right">
                                            <h4>${{ jobListAverage(monthDayTotals(processMonth), 'receivedTotal') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>{{ jobNumberTotal(monthDayTotals(processMonth), 'invoiceJobs') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>${{ jobListTotal(monthDayTotals(processMonth), 'invoiceTotal') }}</h4>
                                        </td>
                                        <td class="text-right border-right">
                                            <h4>${{ jobListAverage(monthDayTotals(processMonth), 'invoiceTotal') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>{{ jobNumberTotal(monthDayTotals(processMonth), 'productionTotal') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>${{ jobListTotal(monthDayTotals(processMonth), 'productionTotal') }}</h4>
                                        </td>
                                        <td class="text-right border-right">
                                            <h4>${{ jobListAverage(monthDayTotals(processMonth), 'productionTotal') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>{{ jobListTotal(monthDayTotals(processMonth), 'totalWorkHours') }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 v-if="jobListTotalSub(monthDayTotals(processMonth), 'totalWorkHours') != 0">${{ numberWithCommas(jobListTotalSub(monthDayTotals(processMonth), 'invoiceTotal')/jobListTotalSub(monthDayTotals(processMonth), 'totalWorkHours'), 2) }}</h4>
                                        </td>
                                        <td class="text-right">
                                            <h4 v-if="jobListTotalSub(monthDayTotals(processMonth), 'payTotal') != 0">${{ numberWithCommas(jobListTotalSub(monthDayTotals(processMonth), 'invoiceTotal')/jobListTotalSub(monthDayTotals(processMonth), 'payTotal'), 2) }}</h4>
                                        </td>
                                    </tr>
                                    <tr v-for="(dataRow, week) in dataWeeks(processMonth)" :key="week">
                                        <td class="border-right">
                                            <div class="ml-4">{{ week }} <v-chip size="x-small" variant="flat" class="ml-1">{{ jobListWorkingOnly(weekDayTotals(week)).length }} Days</v-chip></div>
                                        </td>
                                        <td class="text-right">
                                            {{ jobNumberTotal(weekDayTotals(week), 'receivedJobs') }}
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:;" @click="setShowJobs(weekDayTotals(week), 'receivedJobs')">${{ jobListTotal(weekDayTotals(week), 'receivedTotal') }}</a>
                                        </td>
                                        <td class="text-right border-right">
                                            ${{ jobListAverage(weekDayTotals(week), 'receivedTotal') }}
                                        </td>
                                        <td class="text-right">
                                            {{ jobNumberTotal(weekDayTotals(week), 'invoiceJobs') }}
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:;" @click="setShowJobs(weekDayTotals(week), 'invoiceJobs')">${{ jobListTotal(weekDayTotals(week), 'invoiceTotal') }}</a>
                                        </td>
                                        <td class="text-right border-right">
                                            ${{ jobListAverage(weekDayTotals(week), 'invoiceTotal') }}
                                        </td>

                                        <td class="text-right">
                                            {{ jobNumberTotal(weekDayTotals(week), 'productionJobs') }}
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:;" @click="setShowJobs(weekDayTotals(week), 'productionJobs')">${{ jobListTotal(weekDayTotals(week), 'productionTotal') }}</a>
                                        </td>
                                        <td class="text-right border-right">
                                            ${{ jobListAverage(weekDayTotals(week), 'productionTotal') }}
                                        </td>

                                        <td class="text-right">
                                            {{ numberWithCommas(jobListTotalSub(weekDayTotals(week), 'totalWorkHours'), 2) }}
                                        </td>
                                        <td class="text-right">
                                            <span v-if="jobListTotalSub(weekDayTotals(week), 'totalWorkHours') != 0">${{ numberWithCommas(jobListTotalSub(weekDayTotals(week), 'invoiceTotal')/jobListTotalSub(weekDayTotals(week), 'totalWorkHours'), 2) }}</span>
                                        </td>
                                        <td class="text-right">
                                            <span v-if="jobListTotalSub(weekDayTotals(week), 'payTotal') != 0">${{ numberWithCommas(jobListTotalSub(weekDayTotals(week), 'invoiceTotal')/jobListTotalSub(weekDayTotals(week), 'payTotal'), 2) }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                    </v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="showDayJobs"
                  width="auto">
            <v-card width="800">
                <v-table v-if="lineTimes.length > 0" density="compact">
                    <thead>
                        <tr class="bg-blue-grey-lighten-5">
                            <th class="text-left w-10">
                                Job
                            </th>
                            <th class="text-left w-20">
                                Contact
                            </th>
                            <th class="text-left w-15">Start Time</th>
                            <th class="text-left w-15">End Time</th>
                            <th class="text-left w-10">Minutes</th>
                            <th class="text-right w-10">
                                $
                            </th>
                            <th class="text-right w-10">
                                $ per/Hr
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dayJob in lineTimes" :key="dayJob.id">
                            <td><job-link v-if="dayJob.jobId" :job="dayJob"></job-link><span v-if="!dayJob.id">{{ dayJob.name }}</span></td>
                            <td>{{ dayJob.contact }}</td>
                            <td>{{ $dayjs(dayJob.startTime).format("hh:mma") }}</td>
                            <td>{{ $dayjs(dayJob.endTime).format("hh:mma") }}</td>
                            <td>{{ dayJob.lineTime }}</td>
                            <td class="text-right">${{ numberWithCommas(dayJob.taskTotal, 2) }}</td>
                            <td>${{ numberWithCommas(dayJob.taskTotal/(dayJob.lineTime/60), 2) }}</td>
                        </tr>
                    </tbody>
                </v-table>
                <v-table v-if="dayJobs.length > 0" density="compact">
                    <thead>
                        <tr class="bg-blue-grey-lighten-5">
                            <th class="text-left w-30">
                                Job
                            </th>
                            <th class="text-left w-30">
                                Cust#
                            </th>
                            <th class="text-left w-20">
                                Contact
                            </th>
                            <th class="text-right w-10">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dayJob in dayJobs.sort((a, b) => b.jobTotal - a.jobTotal)" :key="dayJob.id">
                            <td><job-link v-if="dayJob.id" :job="dayJob"></job-link><span v-if="!dayJob.id">{{ dayJob.name }}</span></td>
                            <td>{{ dayJob.customerNumber }}</td>
                            <td>{{ dayJob.contact }}</td>
                            <td class="text-right">${{ numberWithCommas(dayJob.jobTotal, 2) }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showCommentary"
                  width="auto">
            <v-card width="800">
                <v-form v-model="valid" @submit.prevent="submit" :disabled="savingCommentary">
                    <v-card-title class="d-flex justify-space-between align-center">
                        <div class="text-medium-emphasis ps-2" v-if="commentaryDate">
                            Commentary for {{ commentaryDate.format('dddd DD/MM/YY')  }}
                        </div>
                        <v-btn icon="mdi-close"
                               variant="text"
                               @click="showCommentary = false"></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <p>Select one or more positive/negative reasons for performance.</p>
                                <v-chip-group selected-class="text-primary"
                                              v-model="selectedReasons"
                                              multiple>
                                    <div v-for="tagGroup in reasonChips" :key="tagGroup.group">
                                        <small>{{ tagGroup.group }}</small><br />
                                        <v-chip v-for="tag in tagGroup.chips"
                                                size="x-small"
                                                :prepend-icon="tag.icon"
                                                :key="tag.reason">
                                            {{ tag.reason }}
                                        </v-chip>
                                    </div>
                                </v-chip-group>
                                <v-divider class="mb-4"></v-divider>
                                <div class="mb-2">Commentary</div>
                                <v-textarea :counter="300"
                                            class="mb-2"
                                            rows="2"
                                            :rules="[$rules.required]"
                                            v-model="dayCommentary"
                                            variant="outlined"
                                            persistent-counter></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <v-card variant="outlined" class="mb-2" v-for="note in dayCommentaryHistory" :key="note.body">
                                    <v-card-text>
                                        <v-chip v-for="reason in note.reasons" :key="reason"
                                                size="x-small"
                                                :prepend-icon="getTagFromIndex(reason).icon"
                                                class="mr-1">
                                            {{ getTagFromIndex(reason).reason }}
                                        </v-chip>
                                        <b class="mt-2">Commentary</b><br />
                                        {{ note.body}}
                                        <v-divider class="my-2"></v-divider>
                                        <div class="mb-2">
                                            <div class="d-inline-block text-left float-left">
                                                <small><v-icon icon="mdi-account-outline"></v-icon> {{ note.by }}</small>
                                            </div>
                                            <div class="d-inline-block text-right float-right">
                                                <small><v-icon icon="mdi-clock-outline"></v-icon> {{ note.created }}</small>
                                            </div>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-btn :loading="savingCommentary"
                               flat
                               :disabled="!valid"
                               type="submit"
                               class="mr-2"
                               color="primary"
                               prepend-icon="mdi-floppy">SAVE</v-btn>
                        <v-btn text="Close"
                               @click="showCommentary = false"></v-btn>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .card-legend {
        margin-top:5px;
    }
</style>
<script>
    import { Bar } from 'vue-chartjs'
    import { mapActions } from 'vuex';
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            savingCommentary: false,
            valid: false,
            dashData: null,
            graphLabels: null,
            graphOptions: {},
            graphData: null,
            commentaryIndex: null,
            graphCommentary: null,
            showCommentary: false,
            commentaryDate: null,
            showDayJobs: false,
            dayJobs: [],
            lineTimes: [],
            loaded: false,
            selectedReasons: null,
            dayCommentary: '',
            dayCommentaryHistory: [],
            weeklySummaryFields: [
                { header: 'Total Production', field: 'totalProduction', type: 'currency', hideBlank: false, showJobs: true},
                { header: 'Total Invoiced', field: 'totalInvoiced', type: 'currency', hideBlank: false, showJobs: true },
                { header: 'Line 1 Total Invoiced', field: 'totalInvoicedLine1', type: 'currency', hideBlank: false, showJobs: false },
                { header: 'Line 2 Total Invoiced', field: 'totalInvoicedLine2', type: 'currency', hideBlank: false, showJobs: false },
                { header: 'Total Credits', field: 'totalCredits', type: 'currency', hideBlank: true, showJobs: false },
                { header: 'Total Hr Work', field: 'totalHoursWorked', type: 'decimal', hideBlank: false, showJobs: false },
                { header: 'Target Achievement', field: 'totalInvoiced', type: 'percentagetarget', hideBlank: false, showJobs: false },
                { header: 'Revenue /Hr', field: 'revenueHours', type: 'currency', hideBlank: false, showJobs: false },
                { header: 'Line 1 Uptime', field: 'totalTimeLine1', type: 'decimal', hideBlank: false, showJobs: true },
                { header: 'Line 2 Uptime', field: 'totalTimeLine2', type: 'decimal', hideBlank: false, showJobs: true },
                { header: 'Line 1 $ per/Hr', field: 'averageDollarHourLine1', type: 'currency', hideBlank: false, showJobs: false },
                { header: 'Line 2 $ per/Hr', field: 'averageDollarHourLine2', type: 'currency', hideBlank: false, showJobs: false },
            ],
            reasonChips: [
                {
                    group: 'Good',
                    chips: [
                        { icon: 'mdi-thumb-up-outline', reason: 'Work properly prepared' },
                        { icon: 'mdi-thumb-up-outline', reason: 'Long runs' },
                    ]
                },
                {
                    group: 'Inwards',
                    chips: [
                        { icon: 'mdi-inbox-arrow-down', reason: 'General' },
                        { icon: 'mdi-inbox-arrow-down', reason: 'Damaged product or not acceptable condition' },
                        { icon: 'mdi-inbox-arrow-down', reason: 'Incorrect count' },
                        { icon: 'mdi-inbox-arrow-down', reason: 'Product not labelled' },
                    ]
                },
                {
                    group: 'Pretreatment',
                    chips: [
                        { icon: 'mdi-spray-bottle', reason: 'General' },
                        { icon: 'mdi-spray-bottle', reason: 'Product not dry' },
                        { icon: 'mdi-spray-bottle', reason: 'Product not cleaned fully' },
                    ]
                },
                {
                    group: 'Hanging',
                    chips: [
                        { icon: 'mdi-hanger', reason: 'General' },
                        { icon: 'mdi-hanger', reason: 'Fell in oven' },
                        { icon: 'mdi-hanger', reason: 'Not hung correctly for faces to coat' },
                        { icon: 'mdi-hanger', reason: 'Touched in oven' },
                        { icon: 'mdi-hanger', reason: 'Pieces missing from job' },
                        { icon: 'mdi-hanger', reason: 'Product dirty' },
                    ]
                },
                {
                    group: 'Powder',
                    chips: [
                        { icon: 'mdi-spray', reason: 'General' },
                        { icon: 'mdi-spray', reason: 'Too much powder' },
                        { icon: 'mdi-spray', reason: 'Not enough powder' },
                        { icon: 'mdi-spray', reason: 'Wrong colour' },
                        { icon: 'mdi-spray', reason: 'Powder colour cross contamination' },
                    ]
                },
                {
                    group: 'Packing',
                    chips: [
                        { icon: 'mdi-archive-outline', reason: 'General' },
                        { icon: 'mdi-archive-outline', reason: 'Damaged during packing' },
                        { icon: 'mdi-archive-outline', reason: 'Incorrect packing type' },
                        { icon: 'mdi-archive-outline', reason: 'Pieces missing from job' },
                    ]
                },
                {
                    group: 'Dispatch',
                    chips: [
                        { icon: 'mdi-truck-outline', reason: 'Dispatch general' },
                        { icon: 'mdi-truck-outline', reason: 'Product damaged' },
                        { icon: 'mdi-truck-outline', reason: 'Incorrect product dispatched' },
                    ]
                },
                {
                    group: 'Planning',
                    chips: [
                        { icon: 'mdi-calendar-blank-outline', reason: 'Planning general' },
                        { icon: 'mdi-calendar-blank-outline', reason: 'No powder' },
                        { icon: 'mdi-calendar-blank-outline', reason: 'Job not ready' },
                        { icon: 'mdi-calendar-blank-outline', reason: 'Missing job detail' },
                    ]
                }
            ]
        };
    }
    export default {
        name: "PerformanceDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            let getCurrentCommentary = this.getCurrentCommentary;
            this.graphOptions = {
                responsive: true,
                maintainAspectRatio: false,
                onClick: (event, array) => {
                    if (array[0]) {
                        let barData = array[0];
                        let dayString = this.graphLabels[barData.index].substring(3);
                        this.showCommentary = true;
                        this.commentaryIndex = barData.index;
                        this.commentaryDate = this.$dayjs(dayString, "DD/MM/YY");
                        // Get commentary
                        this.selectedReasons = null;
                        this.dayCommentary = null;
                        this.dayCommentaryHistory = [];
                        if (this.graphCommentary[barData.index]) {
                            this.dayCommentaryHistory = this.graphCommentary[barData.index];
                        }
                    }
                },
                onHover: (event, chartElement) => {
                    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return '$' + numberWithCommas(Math.round(context.parsed.y * 100) / 100, 2);
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: (label) => '$' + numberWithCommas(Math.round(label * 100) / 100000) + "k"
                        }
                    },
                    x: {
                        ticks: {
                            callback: function (val, index) {
                                var commentaryData = getCurrentCommentary(index).length > 0 ? '\u2709 ' : '';
                                return commentaryData + this.getLabelForValue(val);
                            },
                        },
                    }
                },
            };
            this.loading = true;
            this.$api.get('dashboard/performance')
                .then(res => {
                    this.loading = false;
                    this.dashData = res.data;
                    this.buildGraphData();
                })
                .catch(error => {
                    this.loading = false;
                    this.showErrorSnack('There was an error loading the dashboard.');
                });
        },
        components: {
            Bar
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            numberWithCommas,
            getCurrentCommentary(index) {
                return this.graphCommentary[index];
            },
            submit() {
                this.savingCommentary = true;
                const data = new FormData();
                let noteBody = {
                    reasons: this.selectedReasons,
                    body: this.dayCommentary
                };
                var body = JSON.stringify(noteBody);
                data.append('Body', body);
                data.append('ClassName', this.commentaryDate.format('YYYY-MM-DD'));
                data.append('Type', '2');
                this.$api.post("/notes/create", data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.savingCommentary = false;
                    this.commentaryDate = null;
                    this.selectedReasons = null;
                    this.dayCommentary = null;
                    this.showCommentary = false;
                    this.graphCommentary[this.commentaryIndex] = body;
                    this.commentaryIndex = null;
                    this.$refs.bar.chart.update();
                })
                .catch(error => {
                    this.savingCommentary = false;
                    this.showErrorSnack("Sorry we couldn't save the commentary for today");
                });
            },
            setShowJobs(dayTotalJobs, type) {
                this.showDayJobs = true;
                this.dayJobs = this.getFullJobList(dayTotalJobs, type);
            },
            setDayJobs(header, summary){
                this.dayJobs = [];
                this.lineTimes = [];
                if (header == 'Total Production'){
                    this.dayJobs = summary.production;
                } else if (header == 'Total Invoiced'){
                    this.dayJobs = summary.invoices;
                } else if (header == 'Line 1 Uptime'){
                    this.lineTimes = summary.line1Times;
                } else if (header == 'Line 2 Uptime'){
                    this.lineTimes = summary.line2Times;
                }
                this.showDayJobs = true;
            },
            getTargetPercentage(total, target, showOver = false) {
                if (!showOver && total > target) {
                    return 100;
                } else {
                    return (total / target) * 100;
                }
            },
            jobListTotalSub(dayTotalJobs, type) {
                return dayTotalJobs.reduce((acc, obj) => acc + obj[type], 0);
            },
            jobListTotal(dayTotalJobs, type) {
                return this.numberWithCommas(this.jobListTotalSub(dayTotalJobs, type), 2);
            },
            jobListAverage(dayTotalJobs, type) {
                let workingOnly = this.jobListWorkingOnly(dayTotalJobs);
                if (workingOnly.length != 0) {
                    return this.numberWithCommas(dayTotalJobs.reduce((acc, obj) => acc + obj[type], 0) / workingOnly.length, 2);
                }
                return 0.00;
            },
            jobListWorkingOnly(dayTotalJobs) {
                return dayTotalJobs.filter(element => {
                    return element.workingDay === true;
                });
            },
            jobNumberTotal(dayTotalJobs, type) {
                return this.getFullJobList(dayTotalJobs, type).length;
            },
            getFullJobList(dayTotalJobs, type) {
                let fullJobList = [];
                dayTotalJobs.forEach(dayTotal => {
                    fullJobList = fullJobList.concat(dayTotal[type]);
                });
                return fullJobList;
            },
            weekDayTotals(week) {
                return this.dashData.dayTotals.filter(element => {
                    return element.week == week;
                });
            },
            monthDayTotals(month) {
                return this.dashData.dayTotals.filter(element => {
                    return element.month == month;
                });
            },
            dataWeeks(month) {
                let data = this.dashData.dayTotals.filter(element => {
                    return element.month == month;
                });
                return this.groupBy(data.reverse(), 'week');
            },
            groupBy(array, field) {
                return array.reduce((acc, obj) => {
                    const key = obj[field];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
            },
            getTagFromIndex(index) {
                let flatChips = [];
                this.reasonChips.forEach(group => {
                    group.chips.forEach(chip => {
                        flatChips.push(chip);
                    });
                });
                return flatChips[index];
            },
            buildGraphData() {
                if (this.dashData && this.dashData.dayTotals) {
                    var dayTotals = this.dashData.dayTotals.filter(item => {
                        return item.showGraph;
                    });
                    this.graphLabels = dayTotals.map(item => { return this.$dayjs(item.day).format('dd DD/MM/YY'); });
                    this.graphCommentary = dayTotals.map(item => { return item.commentary; });
                    this.graphData = {
                        labels: this.graphLabels,
                        datasets: [
                            {
                                label: 'Production',
                                fill: true,
                                tension: 0.1,
                                backgroundColor: '#546E7A',
                                data: dayTotals.map(item => { return item.productionTotal; })
                            },
                            {
                                label: 'Invoiced',
                                fill: true,
                                tension: 0.1,
                                backgroundColor: '#536dfe',
                                data: dayTotals.map(item => { return item.invoiceTotal; })
                            },
                            {
                                label: 'Received',
                                fill: false,
                                tension: 0.1,
                                backgroundColor: '#00acc1',
                                data: dayTotals.map(item => { return item.receivedTotal; })
                            },
                            {
                                label: 'Target',
                                fill: false,
                                tension: 0.1,
                                borderColor: '#4CAF50',
                                data: dayTotals.map(item => { return this.dashData.dayTarget; }),
                                type: 'line',
                            },
                        ]
                    };
                    this.loaded = true;
                }
            },
        },
        computed: {
            weekTargetProgress() {
                if (this.dashData) {
                    return this.getTargetPercentage(this.dashData.weekTargetProgress, this.dashData.weekTarget);
                }
                return 0;
            },
            monthTargetProgress() {
                if (this.dashData) {
                    return this.getTargetPercentage(this.dashData.monthTargetProgress, this.dashData.monthTarget);
                }
                return 0;
            },
        }
    };
</script>