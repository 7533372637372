// Import definitions
import ApplicationUserDef from './entityDefinitions/ApplicationUser';
import JobItemTemplateDef from './entityDefinitions/JobItemTemplate';
import ColourGroupDef from './entityDefinitions/ColourGroup';
import ContactGroupDef from './entityDefinitions/ContactGroup';
import JobDef from './entityDefinitions/Job';
import ContactDef from './entityDefinitions/Contact';
import ProductDef from './entityDefinitions/Product';
import ColourDef from './entityDefinitions/Colour';
import InvoiceDef from './entityDefinitions/Invoice';
import MessageTemplateDef from './entityDefinitions/MessageTemplate';
import StaffDef from './entityDefinitions/Staff';
import StockDef from './entityDefinitions/Stock';
import WarehouseTransactionDef from './entityDefinitions/WarehouseTransaction';

export default {
    ApplicationUser: ApplicationUserDef,
    JobItemTemplate: JobItemTemplateDef,
    ColourGroup: ColourGroupDef,
    ContactGroup: ContactGroupDef,
    Job: JobDef,
    Contact: ContactDef,
    Product: ProductDef,
    Colour: ColourDef,
    Invoice: InvoiceDef,
    MessageTemplate: MessageTemplateDef,
    Staff: StaffDef,
    Stock: StockDef,
    WarehouseTransaction: WarehouseTransactionDef
}