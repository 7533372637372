<template>
    <div>
        <v-container>
            <v-card :loading="loading">
                <v-card-title class="bg-blue-grey-darken-2">
                    <div class="d-flex justify-space-between">
                        <h1>{{ taskDetails.title }}</h1><h2 v-if="date">{{ date.format('DD-MM-YYYY') }} <v-chip v-if="jobs">{{ completedList.length }} / {{ jobs.length }} <v-icon icon="mdi-check-circle"></v-icon></v-chip></h2>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-list lines="two">
                        <v-list-item v-for="job in orderedJobs"
                                     :key="job.id"
                                     :class="{'list-job':true, 'bg-green-lighten-4':jobRunning(job), 'job-completed':jobCompleted(job), 'job-paused':jobPaused(job)}">
                            <template v-slot:prepend>
                                <div class="prepend-job">
                                    <div v-if="jobRunning(job)">
                                        <v-progress-circular :size="20"
                                                             :width="7"
                                                             color="green"
                                                             indeterminate></v-progress-circular>
                                    </div>
                                    <v-icon v-if="jobCompleted(job)" icon="mdi-check-circle"></v-icon>
                                    <v-icon v-if="jobPaused(job)" icon="mdi-pause-box-outline"></v-icon>
                                </div>
                            </template>
                            <v-list-item-title>
                                <h3>{{ job.name }} <small v-if="jobPaused(job)">(PAUSED)</small></h3>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <div>{{ job.customerNumber }}</div>
                                <div>{{ job.contact.name }}</div>
                            </v-list-item-subtitle>
                            <template v-slot:append>
                                <div class="append-job">
                                    <job-task-buttons-timer v-if="!jobCompleted(job)" :job="jobs[jobs.indexOf(job)]" v-model:refresh="refreshCounter"></job-task-buttons-timer>
                                </div>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>
<style scoped lang="scss">
    .list-job {
        border-bottom:1px solid lightgray;
    }
    .prepend-job {
        width: 25px;
    }
    .job-completed {
        background-color: #e0e0e0 !important;
        color:darkgray;
    }
    .job-paused {
        background-color: #CFD8DC !important;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            date: null,
            jobs: [],
            refreshCounter: 0
        };
    }
    export default {
        name: "LineListView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.date = this.$dayjs();
            this.getData();
        },
        props: {
            task: String,
        },
        methods: {
            ...mapActions([
                'showErrorSnack'
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            getData() {
                this.loading = true;
                let dateSelector = this.date.format("YYYY-MM-DD");
                this.$api.get(`/dashboard/timetable?task=${this.task}&date=${dateSelector}`)
                    .then(res => {
                        this.jobs = res.data;
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the timetables.");
                    });
            },
            jobRunning(job){
                return job.activeTask.running;
            },
            jobCompleted(job){
                return job.jobStatus != this.task || job.activeTask.completed;
            },
            jobPaused(job){
                return job.activeTask.paused;
            }
        },
        computed: {
            taskDetails() {
                return this.$constants.tasks.types[this.task -1];
            },
            completedList() {
                if (this.jobs){
                    return this.jobs.filter(job => {
                        return this.jobCompleted(job);
                    });
                }
                return [];
            },
            orderedJobs() {
                if (this.jobs){
                    let jobCompF = this.jobCompleted;
                    let jobRunF = this.jobRunning;
                    return this.jobs.toSorted((a, b) => {
                        if (jobCompF(a) && !jobCompF(b)) {
                            return 1; // Move `a` to the end if `a` is completed and `b` is not
                        }
                        if (!jobCompF(a) && jobCompF(b)) {
                            return -1; // Move `b` to the end if `b` is completed and `a` is not
                        }
                        if (jobRunF(a) && !jobRunF(b)) {
                            return -1; // Place `a` before `b` if `a` is running and `b` is not
                        }
                        if (!jobRunF(a) && jobRunF(b)) {
                            return 1; // Place `b` before `a` if `b` is running and `a` is not
                        }
                        return 0; // Maintain order if both jobs have the same properties
                    });
                }
                return [];
            }
        },
        watch: {
            refreshCounter: {
                handler: function () {
                    this.getData();
                }
            }
        }
    };
</script>