<template>
    <div>
        <v-data-table-server v-model:items-per-page="itemsPerPage"
                             v-if="typeof(totalItems) != undefined"
                             :headers="tableHeaders"
                             :items-length="totalItems"
                             :items="tableItems"
                             :loading="tableLoading"
                             density="compact"
                             @update:itemsPerPage="itemsPerPageChange"
                             @update:page="pageChange"
                             fixed-header>
            <template v-slot:[`item.name`]="{ item }">
                <job-link :job="item"></job-link>
            </template>
            <template v-slot:[`item.jobTotal`]="{ item }">
                ${{ numberWithCommas(item.jobTotal, 2) }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <type-chip :type="item.type" entity="Job" :object="item"></type-chip>
            </template>
            <template v-slot:[`item.dateCreated`]="{ item }">
                {{ item.dateCreated }}
            </template>
        </v-data-table-server>
    </div>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
            itemsPerPage: 10,
            totalItems: 0,
            tableItems: [],
            tableLoading: false,
            page: 1,
            sortBy: null
        };
    }
    export default {
        name: "JobTablePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadDatatable();
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            loadDatatable() {
                this.tableLoading = true;
                let route = this.$constants.entities.Job.ROUTE + "entityjobs?page=" + this.page + "&itemsPerPage=" + this.itemsPerPage;
                if (this.entity.TYPE == "Contact") {
                    route += "&contactId="+this.object.id;
                }
                if (this.entity.TYPE == "Colour"){

                    route += "&colourId="+this.object.id;
                }
                if (this.sortBy && this.sortBy.length > 0) {
                    route += "&sortBy=" + this.sortBy[0].key + "&sortOrder=" + this.sortBy[0].order;
                }
                this.$api.get(route)
                    .then(res => {
                        this.tableLoading = false;
                        this.tableItems = res.data.items;
                        this.totalItems = res.data.totalItems;
                    })
                    .catch(error => {
                        this.tableLoading = false;
                        this.showErrorSnack('There was an error loading the table data.');
                    });
            },
            itemsPerPageChange(itemsPerPage) {
                this.itemsPerPage = itemsPerPage;
                this.loadDatatable();
            },
            pageChange(page) {
                this.page = page;
                this.loadDatatable();
            },
            numberWithCommas
        },
        computed: {
            ...mapState({
            }),
            jobEntity() {
                return this.$constants.entities.Job;
            },
            tableHeaders() {
                if (this.entity.TYPE == "Contact") {
                    return [
                        { title: 'Name', key: 'name', width: "20%" },
                        { title: 'Customer#', key: 'customerNumber', width: "30%" },
                        { title: 'Project', key: 'projectName', width: "15%"},
                        { title: 'Colour', key: 'colours', width: "15%"},
                        { title: 'Status', key: 'type', width: "10%" },
                        { title: 'Total', key: 'jobTotal', width: "10%" },
                    ];
                }
                if (this.entity.Type == "Colour"){
                    return [
                        { title: 'Name', key: 'name', width: "25%" },
                        { title: 'Customer#', key: 'customerNumber', width: "30%" },
                        { title: 'For', key: 'contactName', width: "25%" },
                        { title: 'Status', key: 'type', width: "10%" },
                        { title: 'Total', key: 'jobTotal', width: "10%" },
                    ];
                }
                return [];
            }
        }
    };
</script>