<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field v-model="email"
                      prepend-inner-icon="mdi-email-outline"
                      label="Username"
                      :rules="[$rules.required, $rules.email]"
                      :disabled="editEntity != null"></v-text-field>
        <v-text-field v-model="name"
                      :rules="[$rules.required]"
                      label="Name"></v-text-field>
        <v-text-field v-if="!editEntity" v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[$rules.required, $rules.minChar(8), $rules.alpha]"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters & must have one none alphanumeric character"
                      counter
                      @click:append="showPassword = !showPassword"></v-text-field>
        <v-select label="Role"
                  v-model="role"
                  item-title="title"
                  item-value="level"
                  :items="accessLevels"></v-select>
        <v-text-field v-model="colour"
                      label="Colour"></v-text-field>
        <v-text-field v-model="extension"
                      label="Phone Extension"></v-text-field>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { getRandomHexColor } from '../mixins/colourMixins';
    import { mapActions, mapState } from "vuex";
    import accessLevels from '../constants/auth';
    function initialState() {
        return {
            saving: false,
            loading: false,
            name: "",
            email: "",
            colour: "",
            password: "",
            extension: "",
            role: 8,
            showPassword: false,
            valid: false,
            errors: null,
        };
    }
    export default {
        name: "UserForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.name = this.editEntity.name;
                this.email = this.editEntity.email;
                this.colour = this.editEntity.colour;
                this.role = this.editEntity.accessLevel;
                this.extension = this.editEntity.phoneExtension;
            } else {
                this.colour = getRandomHexColor();
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                } else {
                    formData.append('email', this.email);
                    formData.append('password', this.password);
                }
                formData.append('name', this.name);
                formData.append('colour', this.colour);
                formData.append('accessLevel', this.role);
                formData.append('phoneExtension', this.extension);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            accessLevels() {
                return accessLevels.accessLevels;
            },
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            }
        }
    };
</script>