<template>
    <v-btn prepend-icon="mdi-lock-reset"
           class="border-radius-0"
           v-if="job.status >= $constants.jobs.JOBSTAT_INVOICE"
           @click="resetJob"
           size="small"
           block
           variant="flat"
           :loading="loading"
           color="red-darken-2">
        Reset Job
    </v-btn>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    import { mapActions } from "vuex";
    export default {
        name: "JobReset",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "reloadEntities"
            ]),
            resetJob() {
                this.$confirm(
                    {
                        message: 'This will reset this job back to schedule. Completed history will be lost. Are you sure?',
                        disableKeys: false,
                        auth: false,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.loading = true;
                                this.$api.post('/jobs/resetJob/?id=' + this.job.id)
                                    .then(res => {
                                        this.loading = false;
                                        this.loadEntityRecord({ entityId: this.job.id, entity: this.$constants.entities.Job });
                                    }).catch(error => {
                                        this.showErrorSnack('We ran into an issue');
                                        this.loading = false;
                                    });
                            }
                        }
                    }
                )
            }
        },
        computed: {
        }
    };
</script>