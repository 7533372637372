import { createApp } from 'vue'
import App from './App'
const app = createApp(App);

// Add constants
import rates from './constants/rates'
app.config.globalProperties.$rates = rates

import constants from './constants/constants'
app.config.globalProperties.$constants = constants

// Add helpers
import api from './helpers/api'
app.config.globalProperties.$api = api
import inputRules from './helpers/inputRules'
app.config.globalProperties.$rules = inputRules
import systemFiles from './helpers/systemFiles'
app.config.globalProperties.$files = systemFiles;

// Vue Store
import store from './store/store.js'
app.use(store)

// Vue Router
import router from './routes/router.js'
app.use(router)

//Ganntastic
import ganttastic from '@infectoone/vue-ganttastic'
app.use(ganttastic);

// Vue confirm
import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog'
app.use(Vue3ConfirmDialog);

// Window size
import { VueWindowSizePlugin } from 'vue-window-size/plugin';
app.use(VueWindowSizePlugin);

// V-viewer
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
app.use(VueViewer)

// Add components
// ./components/ExampleComponent.vue -> <example-component></example-component>
const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
    app.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
)

// Chart js
import Chart from 'chart.js/auto';

// Dayjs
import dayjs from 'dayjs'
app.config.globalProperties.$dayjs = dayjs

// Vuetify
import '@/styles/main.scss'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const p8ntrTheme = {
    dark: false,
    colors: {
        background: '#f5f5f5',
        surface: '#FFFFFF',
        primary: '#007bff',
        secondary: '#4f545c'
    },
}

// NProgress
import 'nprogress/nprogress.css'


const vuetify = createVuetify({
    theme: {
        defaultTheme: 'p8ntrTheme',
        themes: {
            p8ntrTheme,
        },
    },
    components: {
        ...components,
        Chart
    },
    defaults: {
        VTextField: { variant: 'outlined', class: 'mt-2' },
        VBtn: { variant: 'tonal' }
    },
    directives,
})
app.use(vuetify)
app.mount('#app')