<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-select label="Brand *"
                  v-model="editColour.brand"
                  :rules="[$rules.required]"
                  :items="brands"></v-select>
        <v-select label="Range"
                  v-model="editColour.range"
                  :items="ranges"></v-select>
        <v-text-field v-model="editColour.name"
                      :rules="[$rules.required]"
                      label="Name"></v-text-field>
        <v-text-field v-model="editColour.code"
                      :rules="[$rules.required]"
                      label="Supplier Product Code"></v-text-field>
        <v-select label="Finish Type"
                  v-model="editColour.finishType"
                  item-title="name"
                  item-value="value"
                  single-line
                  :items="finishTypes"></v-select>
        <v-select label="Group *"
                  v-model="editColour.colourGroup"
                  :rules="[$rules.required]"
                  :loading="colourGroupsLoading"
                  :items="colourGroups"
                  :return-object="true"
                  item-title="name"></v-select>
        <v-row>
            <v-col cols="1">
                <rgb-square :red="editColour.red" :green="editColour.green" :blue="editColour.blue"></rgb-square>
            </v-col>
            <v-col>
                <v-text-field v-model.number="editColour.red"
                              type="number"
                              label="Red"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-model.number="editColour.green"
                              type="number"
                              label="Green"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-model.number="editColour.blue"
                              type="number"
                              label="Blue"></v-text-field>
            </v-col>
        </v-row>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import _cloneDeep from "lodash/cloneDeep";
    import { encodeColourBasic } from "../mixins/entityEncodeMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            editColour: {
                id: null,
                name: "",
                code: "",
                brand: "",
                red: null,
                green: null,
                blue: null,
                colourGroup: null,
            },
            brands: ['Dulux', 'Interpon'],
            ranges: [
                "Duralloy Solid Colour",
                "Duralloy Mannex",
                "Duralloy Pearlescent",
                "Duralloy + Plus Solid Colour",
                "Duralloy + Plus Pearlescent",
                "Protexture",
                "Duratec Zeus",
                "Duratec Intensity",
                "Duratec Eternity",
                "Duratec Elements",
                "Duratec Mannex",
                "Electro",
                "Charisma"
            ],
            finishTypes: [
                { name: 'Matt', value: 1 },
                { name: 'Satin', value: 2 },
                { name: 'Flat', value: 3 },
                { name: 'Gloss', value: 4 },
                { name: 'High Gloss', value: 5 },
            ],
            valid: false,
            errors: null,
        };
    }
    export default {
        name: "ColourForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadColourGroups();
            if (this.editEntity) {
                this.editColour = _cloneDeep(this.editEntity);
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "loadColourGroups",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                formData = encodeColourBasic(formData, this.editColour);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title ]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
                colourGroups: (state) => state.colourGroups.colourGroups,
                colourGroupsLoading: (state) => state.colourGroups.colourGroupsLoading
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
        }
    };
</script>