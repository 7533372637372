<template>
    <div>
        <v-skeleton-loader class="mx-auto border"
                           :loading="stockLoading"
                           type="table">
            <v-table class="w-100">
                <thead>
                    <tr>
                        <th class="w-80">Colour</th>
                        <th class="w-20">
                            <v-table class="w-100" density="compact">
                                <tr>
                                    <th class="w-50 text-left">Location</th>
                                    <th class="w-50 text-right">Quantity</th>
                                </tr>
                            </v-table>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in stock" :key="row.id">
                        <td><router-link :to="$constants.entities.Colour.ROUTE+row.id">{{ row.colourName }}</router-link></td>
                        <td>
                            <v-table class="w-100" density="compact">
                                <tr v-for="rack in row.rackLevel" :key="rack.rack">
                                    <td class="w-50 text-left">{{ rack.rack }}</td>
                                    <td class="w-50 text-right">{{ rack.quantity }}</td>
                                </tr>
                            </v-table>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-skeleton-loader>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: []
        };
    }
    export default {
        name: "StockPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadStock();
        },
        components: {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "loadStock",
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            numberWithCommas,
        },
        computed: {
            ...mapState({
                stock: (state) => state.stock.stock,
                stockLoading: (state) => state.stock.stockLoading,
            }),
        }
    };
</script>