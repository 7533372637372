import router from '../../routes/router.js'
import axios from 'axios'
import authConstants from '../../constants/auth.js'
export const auth = {
    state: {
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null,
        loginAlert: null,
        loggingIn: false,
        dashboardTab: JSON.parse(localStorage.getItem('dashboardTab')),
    },
    mutations: {
        setUser(state, loading) {
            state.user = loading;
        },
        setReturnUrl(state, returnUrl) {
            state.returnUrl = returnUrl;
        },
        setLoggingIn(state, loading) {
            state.loggingIn = loading;
        },
        setLoginError(state, error) {
            state.loginError = error;
        },
        setDashboardTab(state, tab) {
            localStorage.setItem('dashboardTab', JSON.stringify(tab));
            state.dashboardTab = tab;
        }
    },
    actions: {
        login({ dispatch, commit, state }, { email, password }) {
            commit("setLoginError", null);
            commit("setLoggingIn", true);
            axios.post(process.env.VUE_APP_API_URL + 'auth/login', {
                Email: email,
                Password: password
            }).then(res => {
                let user = res.data;
                commit("setUser", user);
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                // redirect to previous url or default to home page
                commit("setLoggingIn", false);
                dispatch("showSuccessSnack", "Logged in");
                if (user.contactId) {
                    router.push('/customerjobs')
                } else {
                    router.push(state.returnUrl || '/');
                }
            })
            .catch(error => {
                commit("setLoggingIn", false);
                commit("setLoginError", { title: 'Cannot Login', message: error.response.data, type: 'error' });
            });
        },
        logout({ commit }) {
            commit("setUser", null);
            localStorage.removeItem('user');
            router.push('/login');
        },
        updateDashboardTab({ commit }, tab) {
            commit("setDashboardTab", tab);
        }
    },
    getters: {
        hasSupportAccess: (state) => {
            return state.user && state.user.accessLevel >= authConstants.ACCESS_SUP;
        },
        hasManagerAccess: (state) => {
            return state.user && state.user.accessLevel >= authConstants.ACCESS_MAN;
        },
        hasAdminAccess: (state) => {
            return state.user && state.user.accessLevel >= authConstants.ACCESS_ADM;
        },
        hasAccess: (state) => (level) => {
            return state.user && state.user.accessLevel >= level;
        }
    }
}