<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field v-model="editTemplate.name"
                      :rules="[$rules.required]"
                      label="Name"></v-text-field>
        <label>UOM</label>
        <v-select v-model="editTemplate.uom"
                  item-title="id"
                  item-value="id"
                  :rules="[$rules.required]"
                  persistent-hint
                  single-line
                  :items="uoms"></v-select>
        <hr class="my-3" />
        <h3>Pricing</h3>
        <p>You can add a method pricing rate if required. This is not mandatory as most will be driven from the colour rate.</p>
        <v-text-field v-model="rate"
                      v-if="!editTemplate.id"
                      :hint="rateDetails.hint"
                      :prefix="rateDetails.prefix"
                      :suffix="rateDetails.suffix"
                      persistent-hint
                      type="number"
                      :label="'Method '+rateDetails.title"></v-text-field>
        <div v-if="editTemplate.id">
            <rates-display :type="$constants.rates.RATE_PRICING" :class-id="editTemplate.id" class-name="JobItemTemplate" :mandatory="false" :custom-header="'Method '+rateDetails.title"></rates-display>
        </div>
        <v-text-field v-model.number="editTemplate.calculation"
                      :rules="[$rules.required]"
                      :hint="`Use equation swaps - ${validSwaps.join(', ')}`"
                      prepend-icon="mdi-less-than-or-equal"
                      persistent-hint
                      label="Pricing Calculation"></v-text-field>
        <v-sheet class="my-2 pa-3"
                 border rounded>
            <h4>
                <v-icon color="success"
                        :icon="equationErrors.length == 0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'"></v-icon> Equation Validation
            </h4>
            <equation-display :equation="editTemplate.calculation"></equation-display>
            <v-alert density="compact"
                     v-if="equationErrors.length > 0"
                     type="warning"
                     class="my-2"
                     title="Equation errors found">
                <ul>
                    <li v-for="error in equationErrors" :key="error">{{ error }}</li>
                </ul>
            </v-alert>
        </v-sheet>
        <hr class="my-3" />
        <h3>Tasks</h3>
        <p>Indicate what's tasks are associated with this item template.</p>
        <v-row>
            <v-col>
                <v-select v-model="newTask"
                          :items="selectableTasks"
                          item-title="title"
                          item-value="id"></v-select>
            </v-col>
            <v-col>
                <v-btn color="primary"
                       type="submit"
                       class="mt-2"
                       @click="addTask"
                       :disabled="newTask == null"
                       prepend-icon="mdi-plus">
                    ADD TASK
                </v-btn>
            </v-col>
        </v-row>
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-left">
                        Task
                    </th>
                    <th class="text-left">
                        Mandatory?
                    </th>
                    <th class="text-left"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="task in editTemplate.jobTaskTemplates" :key="task.type">
                    <td>{{ tasksList[task.type].title }}</td>
                    <td><v-checkbox v-model="task.mandatory"></v-checkbox></td>
                    <td>
                        <v-btn prepend-icon="mdi-trash-can-outline" @click="removeTask(task)"
                               size="x-small">DELETE</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid || equationErrors.length > 0 "
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import equations from "../constants/equations";
    import products from "../constants/products";
    import tasks from "../constants/tasks";
    import _cloneDeep from "lodash/cloneDeep";
    import { encodeJobItemTemplateBasic } from '../mixins/entityEncodeMixins';
    function initialState() {
        return {
            saving: false,
            loading: false,
            editTemplate: {
                name: '',
                uom: '',
                calculation: '',
                jobTaskTemplates: []
            },
            valid: false,
            errors: null,
            rate: '',
            newTask: null,
            tasksList: []
        };
    }
    export default {
        name: "JobItemTemplateForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.editTemplate = _cloneDeep(this.editEntity);
            }
            this.tasksList = tasks.types;
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                } else {
                    formData.append('defaultRate', this.rate);
                }
                formData = encodeJobItemTemplateBasic(formData, this.editTemplate);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            addTask() {
                let index = this.editTemplate.jobTaskTemplates.findIndex(x => x.type == this.newTask);
                if (index < 0) {
                    this.editTemplate.jobTaskTemplates.push({ type: this.newTask, mandatory: false });
                    this.editTemplate.jobTaskTemplates.sort((a, b) => a.type - b.type);
                } else {
                    this.showErrorSnack('This task has been already added');
                }
                this.newTask = null;
            },
            removeTask(task) {
                let index = this.editTemplate.jobTaskTemplates.findIndex(x => x.type == task.type);
                this.editTemplate.jobTaskTemplates.splice(index, 1);
            }
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            selectableTasks() {
                return this.tasksList.filter(task => {
                    return this.editTemplate.jobTaskTemplates.findIndex(x => x.type == task.value) < 0;
                });

            },
            rateDetails() {
                return this.$constants.rates.types[this.$constants.rates.RATE_PRICING];
            },
            validSwaps() {
                return equations.validSwaps;
            },
            uoms() {
                return products.uoms;
            },
            hasUom() {
                return this.uom != null;
            },
            equationErrors() {
                var errors = [];
                if (!this.editTemplate.calculation || this.editTemplate.calculation.length == 0) {
                    errors.push('Require equation');
                    return errors;
                }
                let input = this.editTemplate.calculation;
                // Remove valid swaps
                const validOperators = ['+', '-', '*', '/'];
                var lastChar = input[input.length-1];
                if (validOperators.includes(lastChar)) {
                    errors.push('Last character cannot be an opertator (-,+,/ or *)')
                }
                validOperators.push(")"); // Add this after
                this.validSwaps.forEach(item => {
                    // Find the index of the provided string in the input
                    const startIndex = input.indexOf(item);

                    // If the provided string is not found or is at the end of the input, return false
                    if (!(startIndex === -1 || startIndex + item.length === input.length)) {
                        // Get the next character after the provided string
                        const nextCharacter = input[startIndex + item.length];
                        // Check if the next character is a valid operator
                        if (!validOperators.includes(nextCharacter)) {
                            errors.push(`Character after ${item} must be an operator (-,+,/ or *)`);
                        }
                    }
                    input = input.replace(item, '');
                });
                // Define the set of valid characters
                const validCharacters = "+-*/.0123456789()";
                // Check if each character in the input is valid
                let invalidCharacters = [];
                for (const char of input) {
                    if (!validCharacters.includes(char)) {
                        invalidCharacters.push(char);
                    }
                }
                if (invalidCharacters.length > 0) {
                    errors.push(`Character ${invalidCharacters.join(',')} is invalid in equation`);
                }
                return errors;
            }
        }
    };
</script>