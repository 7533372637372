<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field v-model="editTemplate.name"
                      :rules="[$rules.required]"
                      label="Name"></v-text-field>
        <v-select v-model="editTemplate.type"
                  :items="tempTypes"
                  item-title="title"
                  item-value="id"
                  label="Type"></v-select>
        <v-text-field v-model="editTemplate.subject"
                      :rules="[$rules.required]"
                      label="Subject"></v-text-field>
        <small>Body</small>
        <email-body-editor v-model="editTemplate.body" :template="true"></email-body-editor>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error">{{ error[0] }}</li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import templateTypes from "../constants/mailtemplates";
    import _cloneDeep from "lodash/cloneDeep";
    function initialState() {
        return {
            saving: false,
            loading: false,
            editTemplate: {
                name: '',
                type: 0,
                subject: '',
                body: ''
            },
            valid: false,
            errors: null,
        };
    }
    export default {
        name: "MessageTemplateForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.editTemplate = _cloneDeep(this.editEntity);
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                    formData.append('id', this.editEntity.id);
                }
                formData.append('name', this.editTemplate.name);
                formData.append('type', this.editTemplate.type);
                formData.append('subject', this.editTemplate.subject);
                formData.append('body', this.editTemplate.body);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            tempTypes() {
                return templateTypes.types;
            }
        }
    };
</script>