<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex w-100 justify-space-between mb-2 pb-2 border-b-sm">
            <h3 v-if="job"><v-icon icon="mdi-file-tree"></v-icon> {{ job.statusName }}</h3>
            <job-task-buttons v-model:job="localJob" :task="task"></job-task-buttons>
        </div>
        <div>
            <h3>Receive Product Counts</h3>
            <p>Please verify counts for items being received below.</p>
            <v-form v-model="valid" :disabled="loading">
                <v-table v-if="job.jobItems" class="small-table text-caption">
                    <thead>
                        <tr>
                            <th class="text-left w-50">
                                Product
                            </th>
                            <th class="text-right w-20">
                                Expected Qty
                            </th>
                            <th class="text-right w-20">
                                Received Qty
                            </th>
                            <th class="text-left w-10">
                                Damaged?
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="line in job.jobItems" :key="line.id">
                            <td class="text-left"><span v-if="line.description">{{ line.description }}</span><span v-if="!line.description && line.product">{{ line.product.name }}</span> <v-chip v-if="line.line" size="x-small" variant="flat" class="ml-1">{{ line.line }}</v-chip></td>
                            <td class="text-right">{{ line.quantity }}</td>
                            <td class="text-right">
                                <v-text-field reverse
                                              density="compact"
                                              v-if="line.quantity && line.quantity > 0"
                                              v-model.number="line.quantityReceived"></v-text-field>
                                <span v-if="!line.quantity || line.quantity <= 0">0</span>
                            </td>
                            <td class="text-left">
                                <v-checkbox v-model="line.receiveDamaged"></v-checkbox>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="total-subline">
                            <td class="text-right"><h4>TOTAL ITEMS</h4></td>
                            <td class="text-right"><h4>{{ quantityTotal }}</h4></td>
                            <td class="text-right"><h2>{{ quantityReceived }}</h2></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </v-table>
                <v-alert title="Lines with no quantity" icon="mdi-alert-octagram" type="warning" class="my-2" v-if="!allQuantityReceived">
                    All lines must have a quantity received. If the product is missing please add a zero.
                </v-alert>
            </v-form>
            <v-divider class="my-2"></v-divider>
            <h3>Received Images</h3>
            <p>Please upload images of the received product.</p>
            <v-alert title="Damaged Product Found" icon="mdi-information-slab-circle" type="info" class="my-2" v-if="anyDamagedLines">
                Please ensure you take photos of the damaged product identified.
            </v-alert>
            <files-panel :object="job" :entity="entity" tag="Inwards"></files-panel>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions } from "vuex";
    import _cloneDeep from "lodash/cloneDeep";
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            localJob: null,
            valid: false,
        };
    }
    export default {
        name: "JobTaskPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.localJob = this.job;
        },
        props: {
            task: Object,
            job: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            quantityTotal() {
                let result = 0;
                this.job.jobItems.forEach(line => {
                    if (line.quantity) {
                        result += line.quantity;
                    }
                });
                return result;
            },
            quantityReceived() {
                let result = 0;
                this.job.jobItems.forEach(line => {
                    if (line.quantityReceived) {
                        result += line.quantityReceived;
                    }
                });
                return result;
            },
            numberWithCommas,
            allQuantityReceived() {
                let result = true;
                this.job.jobItems.forEach(line => {
                    if ((line.quantity && line.quantity > 0) && !line.quantityReceived) {
                        result = false;
                    }
                });
                return result;
            },
            anyDamagedLines() {
                let result = false;
                this.job.jobItems.forEach(line => {
                    if (line.receiveDamaged) {
                        result = true;
                    }
                });
                return result;
            },
            entity() {
                return this.$constants.entities['Job'];
            }
        },
        watch: {
            localJob: {
                handler() {
                    this.$emit('update:job', this.localJob);
                }
            },
        }
    };
</script>