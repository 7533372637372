<template>
    <v-btn v-if="id && id.length > 0" 
           variant="flat"
           color="blue-lighten-1"
           size="small"
           append-icon="mdi-exit-to-app" :href="link" target="_blank">
        View in Xero
    </v-btn>
</template>
<style scoped>

</style>
<script>
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "XeroInvoiceLink",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            id: String,
        },
        methods: {
        },
        computed: {
            link() {
                return `https://go.xero.com/app/invoicing/view/${this.id}`;
            }
        }
    };
</script>